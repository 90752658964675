import { AppBar, Divider, IconButton, Menu, MenuItem, Tab, Tabs, Toolbar } from '@mui/material';
import { useState } from 'react';
import { NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom';
import logo from '../../../assets/images/Chp_Logo.png';
import PersonIcon from '@mui/icons-material/Person';
import GroupsIcon from '@mui/icons-material/Groups';
import PhoneIcon from '@mui/icons-material/Phone';
import TableChartIcon from '@mui/icons-material/TableChart';
import MedicalInformationIcon from '@mui/icons-material/MedicalInformation';
import BuildIcon from '@mui/icons-material/Build';
import BusinessIcon from '@mui/icons-material/Business';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import MapIcon from '@mui/icons-material/Map';
import WorkIcon from '@mui/icons-material/Work';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Authorization from '../../../utils/Authorization';
import { useDispatch } from 'react-redux';
import { logout } from '../../../store/authentication.slice';

const AdminNavbar = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const authData = new Authorization();

    const routes = location.pathname.split('/');
    const value = `/${routes[1]}/${routes[2]}`;

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const logoutHandler = () => {
        dispatch(logout());
        navigate('/', { replace: true });
    };

    return (
        <>
            {location.pathname.indexOf('admin') > -1 && authData.IsAdmin ? (
                <header>
                    <AppBar color="default" style={{ position: 'sticky', top: 0 }}>
                        <Toolbar>
                            <img src={logo} alt="logo" />
                            <Tabs
                                sx={{
                                    marginX: 'auto',
                                    [`& .MuiTab-root`]: { ':hover': { textDecoration: 'none' } },
                                    [`& .MuiTab-root.Mui-selected`]: { color: '#1976d2', background: 'rgba(25, 118, 210, 0.07)' },
                                }}
                                textColor="inherit"
                                variant="scrollable"
                                scrollButtons="auto"
                                value={value}
                                indicatorColor="primary"
                                TabIndicatorProps={{ style: { height: '5px' } }}
                            >
                                <Tab label="Providers" icon={<PersonIcon />} component={NavLink} value={'/admin/providers'} to="providers" />
                                <Tab label="Members" icon={<GroupsIcon />} component={NavLink} value={'/admin/members'} to="members" />
                                <Tab label="Onboarding" icon={<PhoneIcon />} component={NavLink} value={'/admin/onboarding'} to="onboarding" />
                                <Tab label="Roles" icon={<WorkIcon />} component={NavLink} value={'/admin/roles'} to="roles" />
                                <Tab label="CPT Codes" icon={<WorkIcon />} component={NavLink} value={'/admin/cptcodes'} to="cptcodes" />
                                <Tab label="Teams" icon={<Diversity3Icon />} component={NavLink} value={'/admin/teams'} to="teams" />
                                <Tab label="Regions" icon={<MapIcon />} component={NavLink} value={'/admin/regions'} to="regions" />
                                <Tab label="Facilities" icon={<BusinessIcon />} component={NavLink} value={'/admin/facilities'} to="facilities" />
                                <Tab label="Data Corrections" icon={<BuildIcon />} component={NavLink} value={'/admin/datacorrections'} to="datacorrections" />
                                <Tab label="Health Plan" icon={<MedicalInformationIcon />} component={NavLink} value={'/admin/healthplan'} to="healthplan" />
                                <Tab label="Metric Goals" icon={<TableChartIcon />} component={NavLink} value={'/admin/metricgoals'} to="metricgoals" />
                            </Tabs>
                            <IconButton onClick={handleClick} sx={{ marginLeft: 2, borderRadius: 0, background: 'white' }}>
                                <ArrowDropDownIcon />
                            </IconButton>
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                            >
                                {authData.IsAdmin && (
                                    <MenuItem
                                        onClick={() => {
                                            navigate(`/provider/metricsummary`);
                                            handleClose();
                                        }}
                                    >
                                        Switch to Provider
                                    </MenuItem>
                                )}
                                <Divider />
                                <MenuItem onClick={logoutHandler}>Logout</MenuItem>
                            </Menu>
                        </Toolbar>
                    </AppBar>
                    <Outlet />
                </header>
            ) : null}
        </>
    );
};

export default AdminNavbar;
