import { Divider, List } from '@mui/material';
import { Stack } from '@mui/system';
import React from 'react';
import AppointmentDateRange from './AppointmentDateRange';

interface AppointmentListPanelProps {
    startDate: Date;
    endDate: Date;
    appointmentList: React.ReactNode[];
}

const AppointmentListPanel: React.FC<AppointmentListPanelProps> = ({ startDate, endDate, appointmentList }) => (
    <Stack spacing={2}>
        <AppointmentDateRange startDate={startDate} endDate={endDate} />
        <Divider />
        <List dense>{React.Children.toArray(appointmentList)}</List>
    </Stack>
);

export default AppointmentListPanel;
