export enum CHStatusEnum {
    None = 0,
    Open = 1,
    Pending = 2,
    Duplicated = 3,
    AcceptedWaystar = 4,
    RejectedWaystar = 5,
    AcceptedIntermediary = 9,
    RejectedPayer = 7,
    ReceivedPayer = 8,
    PaidByPayer = 10,
}
