import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from './store';
import { apiString as API_URL } from '../utils/constants';
import axios from 'axios';
import { setIsLoading } from './shared.slice';
import { CPTCodeGroup } from '../Models/Shared/CPTCodeGroup';
import { CPTCode } from '../Models/CPTCodeGroups/CPTCode.model';
import { GridRowId } from '@mui/x-data-grid';

const adminCPTCodesSlice = createSlice({
    name: 'adminCPTCodesSlice',
    initialState: {
        activeCptCodes: [],
        cptCodeGroups: [],
        deleteItemMessage: '',
        selectedGroup: new CPTCodeGroup(),
        selectedIds: [],
        selectedRole: '',
        showAddItemModal: false,
        showAddRoleModal: false,
        showDeleteItemModal: false,
    },
    reducers: {
        _setActiveCPTCodes(state, action) {
            state.activeCptCodes = action.payload;
        },
        _setCPTCodeGroups(state, action) {
            state.cptCodeGroups = action.payload;
        },
        _setDeleteItemMessage(state, action) {
            state.deleteItemMessage = action.payload;
        },
        _setSelectedGroup(state, action) {
            state.selectedGroup = action.payload;
        },
        _setSelectedIds(state, action) {
            state.selectedIds = action.payload;
        },
        _setSelectedRole(state, action) {
            state.selectedRole = action.payload;
        },
        _setShowAddItemModal(state, action) {
            state.showAddItemModal = action.payload;
        },
        _setShowAddRoleModal(state, action) {
            state.showAddRoleModal = action.payload;
        },
        _setShowDeleteItemModal(state, action) {
            state.showDeleteItemModal = action.payload;
        },
    },
});

const {
    _setActiveCPTCodes,
    _setCPTCodeGroups,
    _setDeleteItemMessage,
    _setSelectedGroup,
    _setSelectedIds,
    _setSelectedRole,
    _setShowAddItemModal,
    _setShowAddRoleModal,
    _setShowDeleteItemModal,
} = adminCPTCodesSlice.actions;

export const updateCodeGroups =
    (codeGroup: CPTCodeGroup): AppThunk =>
    async (dispatch, getState) => {
        const cptCodeGroups = getState().adminCPTCodesSlice.cptCodeGroups;
        const updatedCPTCodeGroups = cptCodeGroups.map((group) => (group.Id === codeGroup.Id ? codeGroup : group));
        dispatch(setCPTCodeGroups(updatedCPTCodeGroups));
    };
export const setActiveCPTCodes =
    (value: CPTCode[]): AppThunk =>
    (dispatch) => {
        dispatch(_setActiveCPTCodes(value));
    };

export const setCPTCodeGroups =
    (value: CPTCodeGroup[]): AppThunk =>
    (dispatch) => {
        dispatch(_setCPTCodeGroups(value));
    };

export const setDeleteItemMessage =
    (value: string): AppThunk =>
    (dispatch) => {
        dispatch(_setDeleteItemMessage(value));
    };

export const setSelectedGroup =
    (value: CPTCodeGroup): AppThunk =>
    (dispatch) => {
        dispatch(_setSelectedGroup(value));
    };

export const setSelectedIds =
    (value: GridRowId[]): AppThunk =>
    (dispatch) => {
        dispatch(_setSelectedIds(value));
    };

export const setSelectedRole =
    (value: string): AppThunk =>
    (dispatch) => {
        dispatch(_setSelectedRole(value));
    };

export const setShowAddItemModal =
    (value: boolean): AppThunk =>
    (dispatch) => {
        dispatch(_setShowAddItemModal(value));
    };

export const setShowAddRoleModal =
    (value: boolean): AppThunk =>
    (dispatch) => {
        dispatch(_setShowAddRoleModal(value));
    };

export const setShowDeleteItemModal =
    (value: boolean): AppThunk =>
    (dispatch) => {
        dispatch(_setShowDeleteItemModal(value));
    };

export const fetchCPTCodeGroups = (): AppThunk => async (dispatch) => {
    dispatch(setIsLoading(true));
    const response = await axios.get(`${API_URL}/encounter/getcptcodegroups`);
    dispatch(setCPTCodeGroups(response.data));
    dispatch(setIsLoading(false));
};

export const fetchAllCPTCodes = (): AppThunk => async (dispatch) => {
    dispatch(setIsLoading(true));
    const response = await axios.get(`${API_URL}/encounter/getcptcodes`);
    dispatch(setActiveCPTCodes(response.data));
    dispatch(setIsLoading(false));
};

export const deleteRoleFromCodeGroup =
    (codeGroupId: string, role: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(setIsLoading(true));
        const response = await axios.delete(`${API_URL}/encounter/deleterolefromcodegroup?codeGroupId=${codeGroupId}&role=${role}`);
        const updatedGroup = response.data;
        dispatch(setSelectedGroup(updatedGroup));
        dispatch(updateCodeGroups(updatedGroup));
        dispatch(setSelectedRole(''));
        dispatch(setShowDeleteItemModal(false));
        dispatch(setIsLoading(false));
    };

export const insertOrUpdateCodeGroup =
    (codeGroup: CPTCodeGroup): AppThunk =>
    async (dispatch, getState) => {
        dispatch(setIsLoading(true));
        const response = await axios.post(`${API_URL}/encounter/insertorupdatecodegroup`, codeGroup);
        const updatedCodeGroup = response.data;
        dispatch(setSelectedGroup(updatedCodeGroup));
        dispatch(updateCodeGroups(updatedCodeGroup));
        dispatch(setIsLoading(false));
    };

export const addRoleToCodeGroup =
    (roleName: string): AppThunk =>
    async (dispatch, getState) => {
        const codeGroup = getState().adminCPTCodesSlice.selectedGroup;
        dispatch(setIsLoading(true));
        const response = await axios.post(`${API_URL}/encounter/addroletocodegroup?codeGroupId=${codeGroup.Id}&roleName=${roleName}`);
        const updatedCodeGroup = response.data;
        dispatch(setSelectedGroup(updatedCodeGroup));
        dispatch(updateCodeGroups(updatedCodeGroup));
        dispatch(setIsLoading(false));
    };

export default adminCPTCodesSlice.reducer;
