import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Paper,
    Select,
    Stack,
    Tooltip,
    Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import {
    addRoleToCodeGroup,
    deleteRoleFromCodeGroup,
    fetchCPTCodeGroups,
    setDeleteItemMessage,
    setSelectedGroup,
    setSelectedIds,
    setSelectedRole,
    setShowAddItemModal,
    setShowAddRoleModal,
    setShowDeleteItemModal,
} from '../../store/adminCPTCodesSlice.slice';
import { fetchRoles } from '../../store/adminRoles.slice';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { CPTcodeCategoryEnum } from '../../Enum/CPTcodeCategoryEnum';
import EditRoleCodesModal from './EditRoleCodesModal';
import EditIcon from '@mui/icons-material/Edit';

const DeleteItemModal = (props: any) => {
    return (
        <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="sm">
            <DialogTitle>{props.title}</DialogTitle>
            <DialogContent>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px' }}>
                    <Typography variant="body1">Are you sure you wish to remove {props.message}</Typography>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button sx={{ minWidth: '120px' }} variant="outlined" color="primary" onClick={props.onClose}>
                    No
                </Button>
                <Button sx={{ minWidth: '120px' }} variant="contained" color="error" onClick={props.onConfirm}>
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const AddRoleModal = (props: any) => {
    const dispatch = useDispatch();
    const [role, setRole] = useState('');
    const handleSubmit = () => {
        dispatch(addRoleToCodeGroup(role));
        props.onClose();
    };
    return (
        <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="sm">
            <DialogTitle>Add Role</DialogTitle>
            <DialogContent>
                <FormControl fullWidth sx={{ mt: 1 }}>
                    <InputLabel id="demo-simple-select-label">Role</InputLabel>
                    <Select label="Role" value={role} onChange={(e) => setRole(e.target.value)} sx={{ minWidth: '200px' }}>
                        <MenuItem value="" disabled>
                            Select Role
                        </MenuItem>
                        {props.roles.map((role) => (
                            <MenuItem key={role.Id} value={role.Name}>
                                {role.Name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button sx={{ minWidth: '120px' }} variant="outlined" color="primary" onClick={props.onClose}>
                    Cancel
                </Button>
                <Button sx={{ minWidth: '120px' }} variant="contained" color="primary" onClick={handleSubmit}>
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const AdminCPTCodes = () => {
    const dispatch = useDispatch();
    const { cptCodeGroups, deleteItemMessage, selectedGroup, selectedRole, showAddItemModal, showAddRoleModal, showDeleteItemModal } = useSelector(
        (state: RootState) => state.adminCPTCodesSlice
    );
    const { roles } = useSelector((state: RootState) => state.adminRoles);

    const [handleConfirmDeleteItem, setHandleConfirmDeleteItem] = useState<Function>(null);

    useEffect(() => {
        dispatch(fetchCPTCodeGroups());
        dispatch(fetchRoles());
    }, [dispatch]);

    const handleGroupChange = (event) => {
        const group = cptCodeGroups.find((g) => g.GroupName === event.target.value);
        dispatch(setSelectedGroup(group));
        dispatch(setSelectedRole(''));
    };

    const handleOpenAddCPTCodeModal = () => {
        const selectedRoleCodes = getCPTCodesForSelectedRole();
        dispatch(setSelectedIds(selectedRoleCodes.map((code) => code.Id)));
        dispatch(setShowAddItemModal(true));
    };

    const handleRemoveRole = (selectedRole: string) => {
        dispatch(setSelectedRole(selectedRole));
        dispatch(setDeleteItemMessage(`role ${selectedRole} from ${selectedGroup.GroupName} Code Group?`));
        dispatch(setShowDeleteItemModal(true));
        setHandleConfirmDeleteItem(() => () => handleConfirmRemoveRole(selectedRole));
    };

    const handleConfirmRemoveRole = (selectedRole) => {
        dispatch(deleteRoleFromCodeGroup(selectedGroup.Id, selectedRole));
    };

    const getCPTCodesForSelectedRole = () => {
        const role = selectedGroup.CPTRoleCodes.find((roleCode) => roleCode.Role === selectedRole);
        if (role) {
            const roleCodesCopy = role.Codes.filter((code) => !code.IsDeleted);
            return roleCodesCopy.sort((a, b) => a.Code.localeCompare(b.Code));
        }
        return [];
    };

    return (
        <Grid container spacing={2} p={2}>
            <EditRoleCodesModal open={showAddItemModal} onClose={() => dispatch(setShowAddItemModal(false))} />
            <AddRoleModal open={showAddRoleModal} onClose={() => dispatch(setShowAddRoleModal(false))} roles={roles} />
            <DeleteItemModal
                title="Delete Item"
                open={showDeleteItemModal}
                onClose={() => dispatch(setShowDeleteItemModal(false))}
                onConfirm={handleConfirmDeleteItem}
                message={deleteItemMessage}
            />
            <Grid item xs={12}>
                <Stack direction="row" alignItems={'center'} justifyContent={'space-between'}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">CPT Category</InputLabel>
                        <Select label="CPT Category" value={selectedGroup?.GroupName || ''} onChange={handleGroupChange} sx={{ maxWidth: '200px' }}>
                            <MenuItem value="" disabled>
                                Select CPT Category
                            </MenuItem>
                            {cptCodeGroups.map((group, index) => (
                                <MenuItem key={group.GroupName + `_${index}`} value={group.GroupName}>
                                    {group.GroupName}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Stack>
            </Grid>
            {selectedGroup.GroupName !== '' ? (
                <>
                    <Grid item xs={12} sm={4}>
                        <Paper elevation={3} sx={{ padding: 2 }}>
                            <Stack direction={'row'} justifyContent={'space-between'}>
                                <Typography variant="h6">Roles</Typography>
                                <Tooltip title="Add Role">
                                    <IconButton color="primary" aria-label="add to shopping cart" onClick={() => dispatch(setShowAddRoleModal(true))}>
                                        <AddIcon />
                                    </IconButton>
                                </Tooltip>
                            </Stack>

                            <List>
                                {selectedGroup.CPTRoleCodes.map((roleCode, index) => (
                                    <ListItemButton
                                        key={index}
                                        selected={selectedRole === roleCode.Role}
                                        onClick={() => dispatch(setSelectedRole(roleCode.Role))}
                                    >
                                        <ListItemText primary={roleCode.Role} />
                                        <ListItemIcon>
                                            <IconButton onClick={() => handleRemoveRole(roleCode.Role)}>
                                                <DeleteIcon color="error" />
                                            </IconButton>
                                        </ListItemIcon>
                                    </ListItemButton>
                                ))}
                            </List>
                        </Paper>
                    </Grid>
                    {selectedRole !== '' ? (
                        <Grid item xs={12} sm={8}>
                            <Paper elevation={3} sx={{ padding: 2 }}>
                                <Stack direction={'row'} justifyContent={'space-between'} sx={{ mb: 2 }}>
                                    <Typography variant="h6">
                                        CPT Codes for {selectedRole} ({getCPTCodesForSelectedRole().length})
                                    </Typography>
                                    <Tooltip title="Add/Remove CPT Codes">
                                        <IconButton color="primary" aria-label="add to shopping cart" onClick={handleOpenAddCPTCodeModal}>
                                            <EditIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Stack>
                                <Grid container spacing={2}>
                                    {getCPTCodesForSelectedRole().map((code, index) => (
                                        <Grid item xs={12} sm={6} md={4} key={index}>
                                            <Paper elevation={2} sx={{ padding: 2, position: 'relative' }}>
                                                <Stack spacing={1}>
                                                    <Typography variant="h6">
                                                        <strong>Code:</strong> {code.Code}
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        <strong>Category:</strong> {CPTcodeCategoryEnum[code.CPTcodeCategory]}
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        <strong>Description:</strong> {code.Description}
                                                    </Typography>
                                                </Stack>
                                            </Paper>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Paper>
                        </Grid>
                    ) : (
                        <Grid item xs={12} sm={8} display={'grid'} alignItems={'center'} justifyContent={'center'}>
                            <Typography variant="h6">Select Role</Typography>
                        </Grid>
                    )}
                </>
            ) : (
                <Grid item xs={12} sx={{ minHeight: '50vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography variant="h6">Select CPT Code Category</Typography>
                </Grid>
            )}
        </Grid>
    );
};

export default AdminCPTCodes;
