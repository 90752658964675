import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from './store';
import { apiString as API_URL } from '../utils/constants';
import axios from 'axios';
import { setIsLoading } from './shared.slice';
import { dateFormatter } from '../utils/timeFormat';
import { AllAlerts } from '../Models/HIE/AllAlerts.model';

const hieSlice = createSlice({
    name: 'hie',
    initialState: {
        adtAlerts: [],
        alertsByMatchedDates: [],
        alertHistory: [],
        alertId: '',
        alertsList: [],
        allAlerts: [],
        allLabs: [],
        associatedAlerts: [],
        associatedDocuments: [],
        confirmationModalMessage: '',
        confirmationModalOpen: false,
        confirmationModalTitle: '',
        confirmationModalAction: null,
        covidAlerts: [],
        currentAlertTab: 'ADT Alerts',
        endDate: null,
        fuhfumAlerts: [],
        hedisLabAlerts: [],
        helperMessage: '',
        isArchiving: true,
        labAlerts: [],
        labsByMember: [],
        mapToMember: [],
        mcAlerts: [],
        memberName: '',
        memberByAHCCCS: [],
        membersByDOB: [],
        mihaAlerts: [],
        openDeleteModal: false,
        openHelper: false,
        outpatientAlerts: [],
        pageNumber: 0,
        pageSize: 15,
        patientsAdmittedPast24Hours: [],
        patientHospitalizations: [],
        patientPanel: [],
        rows: [],
        rowSelectionModel: [],
        selectedAlert: null,
        selectedMember: null,
        showArchivedAlerts: false,
        startDate: null,
        teamNotificationModalOpen: false,
        totalAlerts: 0,
        top3Matches: [],
        unmatchedId: '',
        visitTypes: [],
    },
    reducers: {
        _setADTAlerts(state, action) {
            state.adtAlerts = action.payload;
        },
        _setAlertsList(state, action) {
            state.alertsList = action.payload;
        },
        _setAlertsByMatchedDates(state, action) {
            state.alertsByMatchedDates = action.payload;
        },
        _setAlertHistory(state, action) {
            state.alertHistory = action.payload;
        },
        _setAlertId(state, action) {
            state.alertId = action.payload;
        },
        _setAllAlerts(state, action) {
            state.allAlerts = action.payload;
        },
        _setConfirmationModalAction(state, action) {
            state.confirmationModalAction = action.payload;
        },
        _setCovidAlerts(state, action) {
            state.covidAlerts = action.payload;
        },
        _setCurrentAlertTab(state, action) {
            state.currentAlertTab = action.payload;
        },
        _setShowArchivedAlerts(state, action) {
            state.showArchivedAlerts = action.payload;
        },
        _setAssociatedAlerts(state, action) {
            state.associatedAlerts = action.payload;
        },
        _setAssociatedDocuments(state, action) {
            state.allLabs = action.payload;
        },
        _setConfirmationModalMessage(state, action) {
            state.confirmationModalMessage = action.payload;
        },
        _setConfirmationModalOpen(state, action) {
            state.confirmationModalOpen = action.payload;
        },
        _setConfirmationModalTitle(state, action) {
            state.confirmationModalTitle = action.payload;
        },
        _setEndDate(state, action) {
            state.endDate = action.payload;
        },
        _setFuhFumAlerts(state, action) {
            state.fuhfumAlerts = action.payload;
        },
        _setHedisLabAlerts(state, action) {
            state.hedisLabAlerts = action.payload;
        },
        _setHelperMessage(state, action) {
            state.helperMessage = action.payload;
        },
        _setIsArchiving(state, action) {
            state.isArchiving = action.payload;
        },
        _setLabAlerts(state, action) {
            state.labAlerts = action.payload;
        },
        _setLabsByMember(state, action) {
            state.labsByMember = action.payload;
        },
        _setMapToMember(state, action) {
            state.mapToMember = action.payload;
        },
        _setMCAlerts(state, action) {
            state.mcAlerts = action.payload;
        },
        _setMemberName(state, action) {
            state.memberName = action.payload;
        },
        _setMemberByAHCCCS(state, action) {
            state.memberByAHCCCS = action.payload;
        },
        _setMIHAAlerts(state, action) {
            state.mihaAlerts = action.payload;
        },
        _setOpenDeleteModal(state, action) {
            state.openDeleteModal = action.payload;
        },
        _setOpenHelper(state, action) {
            state.openHelper = action.payload;
        },
        _setOutpatientAlerts(state, action) {
            state.outpatientAlerts = action.payload;
        },
        _setPageNumber(state, action) {
            state.pageNumber = action.payload;
        },
        _setPageSize(state, action) {
            state.pageSize = action.payload;
        },
        _setPatientsAdmittedPast24Hours(state, action) {
            state.patientsAdmittedPast24Hours = action.payload;
        },
        _setPatientHospitalization(state, action) {
            state.patientHospitalizations = action.payload;
        },
        _setPatientPanel(state, action) {
            state.patientPanel = action.payload;
        },
        _setRows(state, action) {
            state.rows = action.payload;
        },
        _setRowSelectionModel(state, action) {
            state.rowSelectionModel = action.payload;
        },
        _setSelectedAlert(state, action) {
            state.selectedAlert = action.payload;
        },
        _setSelectedMember(state, action) {
            state.selectedMember = action.payload;
        },
        _setStartDate(state, action) {
            state.startDate = action.payload;
        },
        _setTeamNotificationModalOpen(state, action) {
            state.teamNotificationModalOpen = action.payload;
        },
        _setTop3Matches(state, action) {
            state.top3Matches = action.payload;
        },
        _setTotalAlerts(state, action) {
            state.totalAlerts = action.payload;
        },
        _setUnmatchedId(state, action) {
            state.unmatchedId = action.payload;
        },
        _setVisitTypes(state, action) {
            state.visitTypes = action.payload;
        },
        _setMembersByDOB(state, action) {
            state.membersByDOB = action.payload;
        },
    },
});

const {
    _setADTAlerts,
    _setAlertsList,
    _setAlertsByMatchedDates,
    _setAlertHistory,
    _setAlertId,
    _setAllAlerts,
    _setAssociatedAlerts,
    _setAssociatedDocuments,
    _setConfirmationModalAction,
    _setConfirmationModalMessage,
    _setConfirmationModalOpen,
    _setConfirmationModalTitle,
    _setCovidAlerts,
    _setCurrentAlertTab,
    _setEndDate,
    _setFuhFumAlerts,
    _setHedisLabAlerts,
    _setHelperMessage,
    _setIsArchiving,
    _setLabAlerts,
    _setLabsByMember,
    _setMapToMember,
    _setMCAlerts,
    _setMemberByAHCCCS,
    _setMemberName,
    _setMembersByDOB,
    _setMIHAAlerts,
    _setOpenDeleteModal,
    _setOpenHelper,
    _setOutpatientAlerts,
    _setPageNumber,
    _setPageSize,
    _setPatientsAdmittedPast24Hours,
    _setPatientHospitalization,
    _setPatientPanel,
    _setRows,
    _setRowSelectionModel,
    _setSelectedAlert,
    _setSelectedMember,
    _setShowArchivedAlerts,
    _setStartDate,
    _setTeamNotificationModalOpen,
    _setTop3Matches,
    _setTotalAlerts,
    _setUnmatchedId,
    _setVisitTypes,
} = hieSlice.actions;

export const setADTAlerts =
    (value: AllAlerts[]): AppThunk =>
    (dispatch) => {
        dispatch(_setADTAlerts(value));
    };

export const setAlertId =
    (value: string): AppThunk =>
    (dispatch) => {
        dispatch(_setAlertId(value));
    };

export const setAlertsList =
    (value: any[]): AppThunk =>
    (dispatch) => {
        dispatch(_setAlertsList(value));
    };

export const setShowArchivedAlerts =
    (value: boolean): AppThunk =>
    (dispatch) => {
        dispatch(_setShowArchivedAlerts(value));
    };

export const setConfirmationModalAction =
    (value: any): AppThunk =>
    (dispatch) => {
        dispatch(_setConfirmationModalAction(value));
    };

export const setConfirmationModalMessage =
    (value: string): AppThunk =>
    (dispatch) => {
        dispatch(_setConfirmationModalMessage(value));
    };

export const setConfirmationModalOpen =
    (value: boolean): AppThunk =>
    (dispatch) => {
        dispatch(_setConfirmationModalOpen(value));
    };

export const setConfirmationModalTitle =
    (value: string): AppThunk =>
    (dispatch) => {
        dispatch(_setConfirmationModalTitle(value));
    };

export const setCovidAlerts =
    (value: any[]): AppThunk =>
    (dispatch) => {
        dispatch(_setCovidAlerts(value));
    };

export const setCurrentAlertTab =
    (value: string): AppThunk =>
    (dispatch) => {
        dispatch(_setCurrentAlertTab(value));
    };

export const setLabAlerts =
    (value: AllAlerts[]): AppThunk =>
    (dispatch) => {
        dispatch(_setLabAlerts(value));
    };

export const setMemberByAHCCCS =
    (value: any[]): AppThunk =>
    (dispatch) => {
        dispatch(_setMemberByAHCCCS(value));
    };

export const setMIHAAlerts =
    (value: AllAlerts[]): AppThunk =>
    (dispatch) => {
        dispatch(_setMIHAAlerts(value));
    };

export const setEndDate =
    (value: Date): AppThunk =>
    (dispatch) => {
        dispatch(_setEndDate(value));
    };

export const setFuhFumAlerts =
    (value: AllAlerts[]): AppThunk =>
    (dispatch) => {
        dispatch(_setFuhFumAlerts(value));
    };

export const setHedisLabAlerts =
    (value: AllAlerts[]): AppThunk =>
    (dispatch) => {
        dispatch(_setHedisLabAlerts(value));
    };

export const setHelperMessage =
    (value: string): AppThunk =>
    (dispatch) => {
        dispatch(_setHelperMessage(value));
    };

export const setIsArchiving =
    (value: boolean): AppThunk =>
    (dispatch) => {
        dispatch(_setIsArchiving(value));
    };

export const setMCAlerts =
    (value: AllAlerts[]): AppThunk =>
    (dispatch) => {
        dispatch(_setMCAlerts(value));
    };

export const setOpenHelper =
    (value: boolean): AppThunk =>
    (dispatch) => {
        dispatch(_setOpenHelper(value));
    };

export const setOutpatientAlerts =
    (value: any): AppThunk =>
    (dispatch) => {
        dispatch(_setOutpatientAlerts(value));
    };

export const setPageNumber =
    (value: number): AppThunk =>
    (dispatch) => {
        dispatch(_setPageNumber(value));
    };

export const setPageSize =
    (value: number): AppThunk =>
    (dispatch) => {
        dispatch(_setPageSize(value));
    };

export const setRows =
    (value: any[]): AppThunk =>
    (dispatch) => {
        dispatch(_setRows(value));
    };

export const setRowSelectionModel =
    (value: any[]): AppThunk =>
    (dispatch) => {
        dispatch(_setRowSelectionModel(value));
    };

export const setSelectedAlert =
    (value: object): AppThunk =>
    (dispatch) => {
        dispatch(_setSelectedAlert(value));
    };

export const setSelectedMember =
    (value: object): AppThunk =>
    (dispatch) => {
        dispatch(_setSelectedMember(value));
    };

export const setTeamNotificationModalOpen =
    (value: boolean): AppThunk =>
    (dispatch) => {
        dispatch(_setTeamNotificationModalOpen(value));
    };

export const setTotalAlerts =
    (value: number): AppThunk =>
    (dispatch) => {
        dispatch(_setTotalAlerts(value));
    };

export const setStartDate =
    (value: Date): AppThunk =>
    (dispatch) => {
        dispatch(_setStartDate(value));
    };

export const setUnmatchedId =
    (value: string): AppThunk =>
    (dispatch) => {
        dispatch(_setUnmatchedId(value));
    };

export const clearSelections = (): AppThunk => (dispatch) => {
    dispatch(_setTop3Matches([]));
    dispatch(_setSelectedMember(null));
    dispatch(_setLabsByMember([]));
    dispatch(_setUnmatchedId(''));
};

export const setTop3Matches =
    (value: any[]): AppThunk =>
    (dispatch) => {
        dispatch(_setTop3Matches(value));
    };

export const setOpenDeleteModal =
    (value: boolean): AppThunk =>
    (dispatch) => {
        dispatch(_setOpenDeleteModal(value));
    };

export const openDeleteAlertHandler =
    (alert: any): AppThunk =>
    (dispatch) => {
        dispatch(_setOpenDeleteModal(true));
        dispatch(_setMemberName(alert.row.Patient));
    };

export const handleOpenHelper =
    (message: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(setHelperMessage(message));
        dispatch(setOpenHelper(true));
    };

export const deleteAlertHandler =
    (ids: string[], callback: Function): AppThunk =>
    async (dispatch) => {
        await axios.delete(`${API_URL}/HIE/deletealert`, { data: ids });
        dispatch(callback());
        dispatch(setOpenDeleteModal(false));
    };

export const archiveAlertHandler =
    (ids: string[], callback: Function): AppThunk =>
    async (dispatch) => {
        await axios.patch(`${API_URL}/HIE/archivealert`, ids);
        dispatch(callback());
        dispatch(closeConfirmationModalHandler(callback));
    };

export const removeAlertFromArchiveHandler =
    (ids: string[], callback: Function): AppThunk =>
    async (dispatch) => {
        await axios.patch(`${API_URL}/HIE/unarchivealert`, ids);
        dispatch(callback());
        dispatch(closeConfirmationModalHandler(callback));
    };

export const openConfirmationHandler =
    (confirmationModalTitle: string, rowSelectionModel: string[], callback: Function): AppThunk =>
    (dispatch) => {
        const message = `Are you sure you wish to ${confirmationModalTitle} the following Alert(s)?`;
        dispatch(setConfirmationModalMessage(message));
        dispatch(setConfirmationModalTitle(confirmationModalTitle));
        dispatch(setConfirmationModalOpen(true));
        if (confirmationModalTitle === 'Archive') {
            dispatch(
                setConfirmationModalAction(() => {
                    dispatch(archiveAlertHandler(rowSelectionModel, callback));
                    dispatch(closeConfirmationModalHandler(callback));
                })
            );
        }
        if (confirmationModalTitle === 'Delete') {
            console.log('callback: ', callback);
            dispatch(
                setConfirmationModalAction(() => {
                    dispatch(deleteAlertHandler(rowSelectionModel, callback));
                    dispatch(closeConfirmationModalHandler(callback));
                })
            );
        }
    };

export const closeConfirmationModalHandler =
    (callback: Function): AppThunk =>
    (dispatch) => {
        dispatch(clearSelections());
        dispatch(setConfirmationModalOpen(false));
        dispatch(setAlertId(''));
        dispatch(callback());
    };

export const getAlertsByMatchedDates =
    (startDate: string, endDate: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setIsLoading(true));
            const response = await axios.get(`${API_URL}/HIE/getalertsbychpdownloaddate?startDate=${startDate}&endDate=${endDate}`);
            dispatch(_setAlertsByMatchedDates(response.data));
            dispatch(_setRows(response.data));
            dispatch(setIsLoading(false));
        } catch (error) {
            console.log(error);
            dispatch(_setAlertsByMatchedDates([]));
            dispatch(_setRows([]));
            dispatch(setIsLoading(false));
        }
    };

export const getAllAlerts = (): AppThunk => async (dispatch) => {
    dispatch(setIsLoading(true));
    const response = await axios.get(`${API_URL}/HIE/getallalerts`);
    dispatch(_setAllAlerts(response.data));
    dispatch(setIsLoading(false));
};

export const getPatientPanel = (): AppThunk => async (dispatch) => {
    const response = await axios.get(`${API_URL}/HIE/getpatientpanel`);
    dispatch(_setPatientPanel(response.data));
};

export const getAlertHistory = (): AppThunk => async (dispatch) => {
    dispatch(setIsLoading(true));
    const response = await axios.get(`${API_URL}/HIE/getalerthistory`);
    dispatch(_setAlertHistory(response.data));
    dispatch(setIsLoading(false));
};

export const getLabsByMember =
    (dob: string, memberName: string, address: string): AppThunk =>
    async (dispatch) => {
        const response = await axios.get(`${API_URL}/HIE/getlabsbymember?dob=${dob}&memberName=${memberName}&address=${address}`);
        dispatch(_setLabsByMember(response.data));
    };

export const getMemberByAHCCCS =
    (ahcccsID: string): AppThunk =>
    async (dispatch) => {
        const response = await axios.get(`${API_URL}/HIE/getmemberbyahcccsid?ahcccs=${ahcccsID}`);
        dispatch(_setMemberByAHCCCS(response.data));
        dispatch(_setTop3Matches([response.data]));
    };

export const getPatientHospitalizations = (): AppThunk => async (dispatch) => {
    const response = await axios.get(`${API_URL}/HIE/getpatienthospitalizations`);
    dispatch(_setPatientHospitalization(response.data));
};

export const getVisitType =
    (visitType: string): AppThunk =>
    async (dispatch) => {
        try {
            const response = await axios.get(`${API_URL}/HIE/getvisittype?visitType=${visitType}`);
            dispatch(_setVisitTypes(response.data));
        } catch (error) {
            console.log(error);
            dispatch(_setVisitTypes([]));
        }
    };

export const getPatientsAdmittedInPast24Hours =
    (providerId: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setIsLoading(true));
            const response = await axios.get(`${API_URL}/HIE/patientsasmittedinpast24hours?providerId=${providerId}`);
            dispatch(_setPatientsAdmittedPast24Hours(response.data));
            dispatch(setIsLoading(false));
        } catch (error) {
            console.log(error);
            dispatch(_setPatientsAdmittedPast24Hours([]));
            dispatch(setIsLoading(false));
        }
    };

export const patchMapToMember =
    (memberId: string, providerName: string, ids: string[], callback: Function, url: string): AppThunk =>
    async (dispatch) => {
        dispatch(setIsLoading(true));
        try {
            const response = await axios.patch(`${API_URL}/HIE/maptomember?memberId=${memberId}&providerName=${providerName}`, [...ids]);
            dispatch(_setMapToMember(response.data));
            dispatch(clearSelections());
            dispatch(callback());
            window.open(`${url}`, '_blank');
            dispatch(setIsLoading(false));
        } catch (error) {
            console.log(error);
            dispatch(_setMapToMember(''));
            dispatch(clearSelections());
            dispatch(callback());
            dispatch(setIsLoading(false));
        }
    };

export const patchUnmatchMember =
    (id: string, providerName: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setIsLoading(true));
            await axios.patch(`${API_URL}/HIE/unmatchmember?Id=${id}&ProviderName=${providerName}`);

            dispatch(clearSelections());
            dispatch(getAlertHistory());
            dispatch(setIsLoading(false));
        } catch (error) {
            console.log(error);
            dispatch(clearSelections());
            dispatch(getAlertHistory());
            dispatch(setIsLoading(false));
        }
    };

export const getAssociatedAlerts =
    (dob: string, memberName: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setIsLoading(true));
            const response = await axios.get(`${API_URL}/HIE/getassociatedalerts?dob=${dob}&memberName=${memberName}`);
            dispatch(_setAssociatedAlerts(response.data));
            dispatch(setIsLoading(false));
        } catch (error) {
            console.log(error);
            dispatch(_setAssociatedAlerts([]));
            dispatch(setIsLoading(false));
        }
    };

export const getAssociatedDocuments =
    (dob: string, memberName: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setIsLoading(true));
            const response = await axios.get(`${API_URL}/HIE/getassociateddocuments?dob=${dob}&memberName=${memberName}`);
            dispatch(_setAssociatedDocuments(response.data));
            dispatch(setIsLoading(false));
        } catch (error) {
            console.log(error);
            dispatch(_setAssociatedDocuments([]));
            dispatch(setIsLoading(false));
        }
    };

export const getADTAlerts = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setIsLoading(true));
        const response = await axios.get(`${API_URL}/HIE/getadtalerts`);
        dispatch(setADTAlerts(response.data));
        dispatch(setIsLoading(false));
    } catch (error) {
        console.log(error);
        dispatch(setADTAlerts([]));
        dispatch(setIsLoading(false));
    }
};

export const getMIHAAlerts = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setIsLoading(true));
        const response = await axios.get(`${API_URL}/HIE/getmihaalerts`);
        dispatch(setMIHAAlerts(response.data));
        dispatch(setIsLoading(false));
    } catch (error) {
        console.log(error);
        dispatch(setMIHAAlerts([]));
        dispatch(setIsLoading(false));
    }
};

export const getOutpatientAlerts = (): AppThunk => async (dispatch, getState) => {
    try {
        const page = getState().hie.pageNumber;
        const pageSize = getState().hie.pageSize;
        dispatch(setIsLoading(true));
        const response = await axios.get(`${API_URL}/HIE/getoutpatientalerts?page=${page}&pageSize=${pageSize}`);
        dispatch(setOutpatientAlerts(response.data?.Data));
        dispatch(setTotalAlerts(response.data?.TotalCount));
        dispatch(setIsLoading(false));
    } catch (error) {
        console.log(error);
        dispatch(setOutpatientAlerts([]));
        dispatch(setIsLoading(false));
    }
};

export const getCovidAlerts = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setIsLoading(true));
        const response = await axios.get(`${API_URL}/HIE/getcovidalerts`);
        dispatch(setCovidAlerts(response.data));
        dispatch(setIsLoading(false));
    } catch (error) {
        console.log(error);
        dispatch(setCovidAlerts([]));
        dispatch(setIsLoading(false));
    }
};

export const getMCAlerts = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setIsLoading(true));
        const response = await axios.get(`${API_URL}/HIE/getmcalerts`);
        dispatch(setMCAlerts(response.data));
        dispatch(setIsLoading(false));
    } catch (error) {
        console.log(error);
        dispatch(setMCAlerts([]));
        dispatch(setIsLoading(false));
    }
};

export const getLabAlerts = (): AppThunk => async (dispatch, getState) => {
    try {
        const page = getState().hie.pageNumber;
        const pageSize = getState().hie.pageSize;
        dispatch(setIsLoading(true));
        const response = await axios.get(`${API_URL}/HIE/getlabalerts?page=${page}&pageSize=${pageSize}`);
        dispatch(setLabAlerts(response.data?.Data));
        dispatch(setTotalAlerts(response.data?.TotalCount));
        dispatch(setIsLoading(false));
    } catch (error) {
        console.log(error);
        dispatch(setLabAlerts([]));
        dispatch(setIsLoading(false));
    }
};

export const getHedisLabAlerts = (): AppThunk => async (dispatch, getState) => {
    try {
        const page = getState().hie.pageNumber;
        const pageSize = getState().hie.pageSize;
        dispatch(setIsLoading(true));
        const response = await axios.get(`${API_URL}/HIE/gethedislabalerts?page=${page}&pageSize=${pageSize}`);
        dispatch(setHedisLabAlerts(response.data?.Data));
        dispatch(setTotalAlerts(response.data?.TotalCount));
        dispatch(setIsLoading(false));
    } catch (error) {
        console.log(error);
        dispatch(setHedisLabAlerts([]));
        dispatch(setIsLoading(false));
    }
};

export const getFuhFumAlerts = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setIsLoading(true));
        const response = await axios.get(`${API_URL}/HIE/getfuhfumalerts`);
        dispatch(setFuhFumAlerts(response.data));
        dispatch(setIsLoading(false));
    } catch (error) {
        console.log(error);
        dispatch(setFuhFumAlerts([]));
        dispatch(setIsLoading(false));
    }
};

export const getMembersByDOB =
    (dob: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setIsLoading(true));
            const response = await axios.get(`${API_URL}/HIE/getmembersbydob?dob=${dateFormatter(new Date(dob))}`);
            dispatch(_setMembersByDOB(response.data));
            dispatch(setIsLoading(false));
        } catch (error) {
            console.log(error);
            dispatch(_setMembersByDOB([]));
            dispatch(setIsLoading(false));
        }
    };

export const getTopThreeMatches =
    (firstName: string, lastName: string, dob: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setIsLoading(true));
            const response = await axios.get(`${API_URL}/HIE/gettopthreematches?firstName=${firstName}&lastName=${lastName}&dob=${dob}`);
            dispatch(_setTop3Matches(response.data));
            dispatch(setIsLoading(false));
        } catch (error) {
            console.log(error);
            dispatch(_setTop3Matches([]));
            dispatch(setIsLoading(false));
        }
    };

export const deleteMultipleAlertsHandler =
    (currentTab: any): AppThunk =>
    async (dispatch, getState) => {
        const rowSelectionModel = getState().hie.rowSelectionModel;
        const alertsList = currentTab.list?.filter((el) => rowSelectionModel.includes(el.Id));
        dispatch(setAlertsList(alertsList));
        if (rowSelectionModel.length > 0) {
            dispatch(openConfirmationHandler('Delete', rowSelectionModel, currentTab.callback));
        } else {
            dispatch(handleOpenHelper('You must select at least one alert to delete.'));
        }
    };

export const archiveMultipleAlertsHandler =
    (currentTab: any): AppThunk =>
    async (dispatch, getState) => {
        const rowSelectionModel = getState().hie.rowSelectionModel;
        const alertsList = currentTab.list?.filter((el) => rowSelectionModel.includes(el.Id));
        dispatch(setAlertsList(alertsList));
        if (rowSelectionModel.length > 0) {
            dispatch(openConfirmationHandler('Archive', rowSelectionModel, currentTab.callback));
        } else {
            dispatch(handleOpenHelper('You must select at least one alert to archive.'));
        }
    };

export default hieSlice.reducer;
