import { Box, Stack, FormControl, Grid, TextField, Tooltip, Typography, Dialog, DialogContent } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import HIEhelp from '../../assets/documents/HIEHelp.pdf';
import { isDefaultDate } from '../../utils/common';
import { dateFormatter } from '../../utils/timeFormat';
import { DataGrid, gridClasses, GridRenderCellParams, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import Button from '@mui/material/Button';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { apiString as API_URL } from '../../utils/constants';
import ConfirmationModal from '../HIE/Modals/ConfirmationModal';
import Authorization from '../../utils/Authorization';
import MatchedHistoryModal from '../HIE/HIETabs/History/MatchedHistoryModal';
import { useParams } from 'react-router-dom';
import { HIEAlertTypeEnum } from '../../Enum/HIEAlertTypeEnum';
import PageviewIcon from '@mui/icons-material/Pageview';
import { AllAlerts } from '../../Models/HIE/AllAlerts.model';
import { noteFormatter } from '../HIE/Modals/TeamNotificationModal';

const MemberDetailHIE = (props: any) => {
    const auth = new Authorization();
    const params = useParams();
    const memberId = props.memberId || params.memberId;

    const [rows, setRows] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
    const [confirmationModalMessage, setConfirmationModalMessage] = useState('');
    const [alertId, setAlertId] = useState('');

    const [labAlert, setLabAlert] = useState<AllAlerts>(null);
    const [showLab, setShowLab] = useState(false);

    const [openHistory, setOpenHistory] = useState(false);
    const [matchedHistory, setMatchedHistory] = useState([]);

    const unMatchSelectedMemberHandler = () => {
        unmatchMemberHandler(alertId, auth.UserName);
    };

    const handleViewLab = (alert: AllAlerts) => {
        setLabAlert(alert);
        setShowLab(true);
    };

    const columns: GridColDef[] = [
        {
            field: 'MemberId',
            headerName: 'Matched Status',
            minWidth: 140,
            renderCell: (params: GridRenderCellParams) => {
                return params.row.MemberId ? (
                    <Button variant="contained" onClick={() => openConfirmationModalHandler(params)}>
                        Unmatch
                    </Button>
                ) : null;
            },
        },
        {
            field: 'MatchedDate',
            headerName: 'Matched Date',
            minWidth: 140,
            valueGetter: (params: GridValueGetterParams) => `${isDefaultDate(params.row.MatchedDate) ? '-' : dateFormatter(new Date(params.row.MatchedDate))}`,
        },
        {
            field: 'History',
            headerName: 'Alert History',
            minWidth: 140,
            renderCell: (params: GridRenderCellParams) => {
                return params.row.History ? (
                    <Tooltip title="View History">
                        <Button startIcon={<WorkHistoryIcon />} onClick={() => openVeiwHistoryHandler(params.row.History)}></Button>
                    </Tooltip>
                ) : null;
            },
        },
        {
            field: 'Patient',
            headerName: 'Name',
            minWidth: 220,
        },
        {
            field: 'DOB',
            headerName: 'DOB',
            minWidth: 140,
            valueGetter: (params: GridValueGetterParams) => `${isDefaultDate(params.row.DOB) ? '-' : dateFormatter(new Date(params.row.DOB))}`,
        },
        {
            field: 'CHPDownloadDate',
            headerName: 'Download Date',
            minWidth: 140,
            valueGetter: (params: GridValueGetterParams) =>
                `${isDefaultDate(params.row.CHPDownloadDate) ? '-' : dateFormatter(new Date(params.row.CHPDownloadDate))}`,
        },
        {
            field: 'VisitType',
            headerName: 'Visit Type',
            minWidth: 140,
            flex: 1,
        },
        {
            field: 'HIEAlertType',
            headerName: 'Alert Type',
            minWidth: 200,
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <>
                        {HIEAlertTypeEnum[params.row.HIEAlertType]}
                        <Tooltip title="View Lab">
                            <Button startIcon={<PageviewIcon />} onClick={() => handleViewLab(params.row)}></Button>
                        </Tooltip>
                    </>
                );
            },
        },
        {
            field: 'SendingFacility',
            headerName: 'Sending Facility',
            minWidth: 280,
            flex: 1,
        },
        {
            field: 'admitDate',
            headerName: 'Admission Date',
            minWidth: 160,
            valueGetter: (params: GridValueGetterParams) => `${isDefaultDate(params.row.AdmitDate) ? 'N/A' : dateFormatter(new Date(params.row.AdmitDate))}`,
        },
        {
            field: 'admitTime',
            headerName: 'Admission Time',
            minWidth: 160,
            valueGetter: (params: GridValueGetterParams) =>
                `${isDefaultDate(params.row.AdmitTime) ? 'N/A' : new Date(params.row.AdmitTime).toLocaleTimeString()}`,
        },
        {
            field: 'dischargeDate',
            headerName: 'Discharge Date',
            minWidth: 160,
            valueGetter: (params: GridValueGetterParams) =>
                `${isDefaultDate(params.row.DischargeDate) ? 'N/A' : dateFormatter(new Date(params.row.DischargeDate))}`,
        },
        {
            field: 'dischargeTime',
            headerName: 'Discharge Time',
            minWidth: 160,
            valueGetter: (params: GridValueGetterParams) =>
                `${isDefaultDate(params.row.DischargeTime) ? 'N/A' : new Date(params.row.DischargeTime).toLocaleTimeString()} `,
        },
    ];

    const handleCloseShowLab = () => {
        setShowLab(false);
    };

    const openConfirmationModalHandler = (params) => {
        setConfirmationModalMessage(`Are you sure you wish to Un-Match ${params.row.Patient}?`);
        setAlertId(params.row.Id);
        setConfirmationModalOpen(true);
    };

    const closeConfirmationModalHandler = () => {
        setConfirmationModalOpen(false);
        setAlertId('');
    };

    const openVeiwHistoryHandler = (params) => {
        setMatchedHistory(params);
        setOpenHistory(true);
    };

    const closeViewHistoryHandler = () => {
        setMatchedHistory([]);
        setOpenHistory(false);
    };

    const getHIEByMemberId = async (memberId: string) => {
        try {
            const response = await axios.get(`${API_URL}/hie/gethiebymemberid?memberId=${memberId}`);
            setRows(response.data);
        } catch (error) {
            // setMemberHIE([]);
            setRows([]);
            console.log(error);
        }
    };

    const getAlertsByMatchedDatesHandler = async (memberId: string, startDate: string, endDate: string) => {
        try {
            const response = await axios.get(`${API_URL}/HIE/getmemberalertsbydates?memberId=${memberId}&startDate=${startDate}&endDate=${endDate}`);
            setRows(response.data);
        } catch (error) {
            console.log(error);
            setRows([]);
        }
    };

    const unmatchMemberHandler = async (id: string, providerName: string) => {
        await axios.patch(`${API_URL}/HIE/unmatchmember?Id=${id}&ProviderName=${providerName}`, { Id: id });
        getHIEByMemberId(memberId);
        closeConfirmationModalHandler();
    };

    useEffect(() => {
        getHIEByMemberId(memberId);
    }, [memberId]);

    return (
        <>
            <ConfirmationModal
                confirmationModalMessage={confirmationModalMessage}
                handleClose={closeConfirmationModalHandler}
                open={confirmationModalOpen}
                handleAction={unMatchSelectedMemberHandler}
                title={'Confirm Un-Match'}
            />
            <MatchedHistoryModal open={openHistory} handleClose={closeViewHistoryHandler} matchedHistory={matchedHistory} />
            <Dialog open={showLab} onClose={handleCloseShowLab} aria-labelledby="form-dialog-title">
                <DialogContent>{noteFormatter(labAlert)}</DialogContent>
            </Dialog>
            <Box>
                <Grid container>
                    <Grid item xs={12} p={2} display={'flex'} alignItems={'center'}>
                        <Typography variant="h5">Member HIE Alerts</Typography>
                        <Tooltip title="HIE Help Document">
                            <InfoIcon
                                color="primary"
                                fontSize="small"
                                cursor="pointer"
                                sx={{ marginLeft: 2 }}
                                onClick={() => window.open(`${HIEhelp}`, '_blank')}
                            />
                        </Tooltip>
                    </Grid>
                    <Grid item xs={2} p={2}>
                        <Stack>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <FormControl>
                                    <DesktopDatePicker
                                        label="Start Date:"
                                        value={startDate}
                                        minDate={new Date('2017-01-01')}
                                        onChange={(newValue) => {
                                            setStartDate(newValue);
                                        }}
                                        renderInput={(params) => <TextField required {...params} />}
                                    />
                                </FormControl>
                                <FormControl sx={{ marginY: 2 }}>
                                    <DesktopDatePicker
                                        label="End Date:"
                                        value={endDate}
                                        minDate={new Date('2017-01-01')}
                                        onChange={(newValue) => {
                                            setEndDate(newValue);
                                        }}
                                        renderInput={(params) => <TextField required {...params} />}
                                    />
                                </FormControl>
                            </LocalizationProvider>
                            <Button
                                sx={{ marginY: 2, minWidth: '120px', marginLeft: 'auto!important' }}
                                disabled={!startDate || !endDate}
                                variant="contained"
                                onClick={() => getAlertsByMatchedDatesHandler(memberId, dateFormatter(startDate), dateFormatter(endDate))}
                            >
                                Search
                            </Button>
                        </Stack>
                    </Grid>
                    <Grid item p={2} xs={12}>
                        <div style={{ width: '100%', height: '80vh' }}>
                            <DataGrid
                                autoHeight
                                rows={rows}
                                columns={columns}
                                pageSize={10}
                                rowsPerPageOptions={[5]}
                                getRowId={(row) => row.Id}
                                sx={{
                                    [`& .${gridClasses.cell}`]: {
                                        py: 1,
                                    },
                                }}
                            />
                        </div>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default MemberDetailHIE;
