export enum HedisMetricStatusEnum {
    CompletedDescription = 'Completed',
    Completed = 1,
    CompletedAndControlledDescription = 'Completed And Controlled',
    CompletedAndControlled = 2,
    CompletedAndNotControlledDescription = 'Completed And Not Controlled',
    CompletedAndNotControlled = 3,
    ExpiredDescription = 'Expired',
    Expired = 4,
}
