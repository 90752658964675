import {
    Alert,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControlLabel,
    Grid,
    Paper,
    Snackbar,
    Typography,
} from '@mui/material';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { isDefaultDate } from '../../../utils/common';
import { AllAlerts } from '../../../Models/HIE/AllAlerts.model';
import { HIEAlertTypeEnum } from '../../../Enum/HIEAlertTypeEnum';

const validateDate = (date) => {
    return moment(date).isValid ? moment.utc(date).format('L') : 'N/A';
};

const validateTime = (time) => {
    return isDefaultDate(time) ? 'N/A' : new Date(time).toLocaleTimeString();
};

export const noteFormatter = (alert: AllAlerts) => {
    return alert ? (
        <>
            <Typography component="div">
                {alert.Patient} - (DOB {alert.DOB}) -{' '}
                <span style={{ fontWeight: 600 }}>
                    {HIEAlertTypeEnum[alert.HIEAlertType]} Alert --- {alert.VisitType}
                </span>
                .
            </Typography>
            {alert.CHPDownloadDate && <Typography variant="body2">Alert Download Date: {new Date(alert.CHPDownloadDate).toLocaleDateString()}</Typography>}
            {alert.MemberAHCCCS && <Typography variant="body2">AHCCCS ID: {alert.MemberAHCCCS}</Typography>}
            {alert.SendingFacility && <Typography variant="body2">Sending Facility: {alert.SendingFacility}</Typography>}
            {alert.ServicingProviderAddress && <Typography variant="body2">Sending Facility Address: {alert.ServicingProviderAddress}</Typography>}
            {alert.VisitType && <Typography variant="body2">Visit Type: {alert.VisitType}</Typography>}
            {alert.VisitStatus && <Typography variant="body2">Visit Status: {alert.VisitStatus}</Typography>}
            {alert.PCP && <Typography variant="body2">PCP: {alert.PCP}</Typography>}
            {alert.Diagnosis && alert.Diagnosis !== 'null' && <Typography variant="body2">Diagnosis: {alert.Diagnosis}</Typography>}
            {alert.Location && <Typography variant="body2">Location: {alert.Location}</Typography>}
            {alert.AdmitReason && <Typography variant="body2">Admit Reason: {alert.AdmitReason}</Typography>}
            {alert.TestPerformed && <Typography variant="body2">Test Performed: {alert.TestPerformed}</Typography>}
            {alert.Result && <Typography variant="body2">Result: {alert.Result}</Typography>}
            {alert.TestOrdered && <Typography variant="body2">Test Ordered: {alert.TestOrdered}</Typography>}
            {alert.LabOrderResult && <Typography variant="body2">Test Result: {alert.LabOrderResult}</Typography>}
            {alert.ObservationLabRequestDate && <Typography variant="body2">Ordered Date: {alert.ObservationLabRequestDate}</Typography>}
            {alert.ObservationLabDate && <Typography variant="body2">Results Date: {alert.ObservationLabDate}</Typography>}
            {alert.AdmitDate && (
                <Typography variant="body2">
                    Admitted: {validateDate(new Date(alert.AdmitDate))} @ {validateTime(new Date(alert.AdmitTime))}
                </Typography>
            )}
            {alert.DischargeDate && (
                <Typography variant="body2" marginBottom={2}>
                    Discharged: {validateDate(new Date(alert.DischargeDate))} @ {validateTime(new Date(alert.DischargeTime))}
                </Typography>
            )}
            {alert.TestPerformed && <Typography variant="body2">Test Performed: {alert.TestPerformed}</Typography>}
        </>
    ) : null;
};

const noteFormatterClipboard = (alert: AllAlerts) => {
    return (
        `${alert.Patient} - (DOB ${alert.DOB}) - ${HIEAlertTypeEnum[alert.HIEAlertType]} Alert.` +
        `${alert.CHPDownloadDate !== null ? `\n\tAlert Download Date: ${new Date(alert.CHPDownloadDate).toLocaleDateString()}` : ''}` +
        `${alert.MemberAHCCCS !== null ? `\n\tMember AHCCCS: ${alert.MemberAHCCCS}` : ''}` +
        `${alert.SendingFacility !== null ? `\n\tSending Facility: ${alert.SendingFacility}` : ''}` +
        `${alert.ServicingProviderAddress !== null ? `\n\tSending Facility Address: ${alert.ServicingProviderAddress}` : ''}` +
        `${alert.VisitType !== null ? `\n\tVisit Type: ${alert.VisitType}` : ''}` +
        `${alert.VisitStatus !== null ? `\n\tVisit Status: ${alert.VisitStatus}` : ''}` +
        `${alert.PCP !== null ? `\n\tPCP: ${alert.PCP}` : ''}` +
        `${alert.Diagnosis && alert.Diagnosis !== 'null' ? `\n\tDiagnosis: ${alert.Diagnosis}` : ''}` +
        `${alert.Location !== null ? `\n\tLocation: ${alert.Location}` : ''}` +
        `${alert.AdmitReason !== null ? `\n\tAdmit Reason: ${alert.AdmitReason}` : ''}` +
        `${alert.TestOrdered !== null ? `\n\tTest Ordered: ${alert.TestOrdered}` : ''}` +
        `${alert.TestPerformed !== null ? `\n\tTest Performed: ${alert.TestPerformed}` : ''}` +
        `${alert.ObservationLabRequestDate !== null ? `\n\tTest Order Date: ${alert.ObservationLabRequestDate}` : ''}` +
        `${alert.ObservationLabDate !== null ? `\n\tTest Result Date: ${alert.ObservationLabDate}` : ''}` +
        `${alert.Result !== null ? `\n\tResult: ${alert.Result}` : ''}` +
        `${alert.AdmitDate !== null ? `\n\tAdmitted: ${validateDate(new Date(alert.AdmitDate))} @ ${validateTime(new Date(alert.AdmitTime))}` : ''}` +
        `${
            alert.DischargeDate !== null
                ? `\n\tDischarged: ${validateDate(new Date(alert.DischargeDate))} @ ${validateTime(new Date(alert.DischargeTime))}`
                : ''
        }\n\n`
    );
};

const TeamNotificationModal = (props) => {
    const { alertsList, selectedMember, handleCancel, handleMatch, open } = props;
    const [copiedNotes, setCopiedNotes] = useState([]);
    const [openAlert, setOpenAlert] = useState(false);

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };

    const copiedNotesHandler = (alert) => {
        const alertIds = copiedNotes.map((a) => a.Id);
        let alertsCopy = [...copiedNotes];
        if (alertIds.includes(alert)) {
            alertsCopy = alertsCopy.filter((a) => a.Id !== alert);
        } else {
            alertsCopy = [...copiedNotes, alertsList.find((a) => a.Id === alert)];
        }
        setCopiedNotes(alertsCopy);
    };

    const copyToClipboardHandler = () => {
        let tmp = '';
        for (let i of copiedNotes) {
            tmp += noteFormatterClipboard(i);
        }
        navigator.clipboard.writeText(tmp);
        setOpenAlert(true);
    };

    useEffect(() => {
        if (alertsList?.length > 0) {
            setCopiedNotes([...alertsList]);
        }
    }, [alertsList]);

    return (
        <Dialog onClose={handleCancel} open={open} sx={{ padding: 5 }} maxWidth="md" fullWidth>
            <Snackbar open={openAlert} autoHideDuration={2500} onClose={handleCloseAlert} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <Alert variant="filled" onClose={handleCloseAlert} severity={'success'} sx={{ width: '100%' }}>
                    Copied to Clipboard
                </Alert>
            </Snackbar>
            <DialogTitle>Notify Team Members of Alert - {`${selectedMember?.FirstName} ${selectedMember?.LastName}`}</DialogTitle>
            <Divider />
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <Typography>
                            NP: <span style={{ fontWeight: 600 }}>{selectedMember?.NPTeam}</span>
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography>
                            CC: <span style={{ fontWeight: 600 }}>{selectedMember?.CCTeam}</span>
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography>
                            BHA: <span style={{ fontWeight: 600 }}>{selectedMember?.BHTeam}</span>
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography>
                            W/E: <span style={{ fontWeight: 600 }}>{selectedMember?.CHWTeam}</span>
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography>
                            CL: <span style={{ fontWeight: 600 }}>{selectedMember?.CLTeam}</span>
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper elevation={5} sx={{ marginY: 2, padding: 2 }}>
                            {alertsList?.map((alert, index) => (
                                <FormControlLabel
                                    key={alert.Id}
                                    label={noteFormatter(alert)}
                                    control={
                                        <Checkbox
                                            checked={copiedNotes.includes(alert)}
                                            value={alert}
                                            id={alert.Id}
                                            onChange={() => copiedNotesHandler(alert.Id)}
                                        />
                                    }
                                />
                            ))}
                        </Paper>
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions>
                <Button
                    disabled={copiedNotes.length === 0 ? true : false}
                    className="button-120"
                    variant="outlined"
                    sx={{ marginRight: 'auto' }}
                    onClick={copyToClipboardHandler}
                >
                    Copy to Clipboard
                </Button>
                <Button className="button-120" variant="contained" onClick={handleMatch}>
                    Match Alert&#40;s&#41;
                </Button>
                <Button className="button-120" variant="outlined" onClick={handleCancel}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};
export default TeamNotificationModal;
