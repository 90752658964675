import { Box, FormControl, InputLabel, MenuItem, Select, Stack, Tab, Tabs, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Provider } from '../../Models/Provider/Provider.model';
import { RootState } from '../../reducers';
import { setSchedulingProvider } from '../../store/officeScheduler.slice';
import { getActiveProviders } from '../../store/provider.slice';
import Authorization from '../../utils/Authorization';
import OfficeConfirmations from '../Calendar/OfficeScheduler/OfficeConfirmations';
import { fetchProvidersUtil } from './schedUtils';

const OfficeSchedulingPanel = (props) => {
    const authData = new Authorization();
    const dispatch = useDispatch();
    const { activeProviders = [] } = useSelector((state: RootState) => state.provider);
    const [selectedProviderId, setSelectedProviderId] = useState<string>('');
    const [schedulers, setSchedulers] = useState<Provider[]>([]);

    // states
    const [tab, setTab] = useState<number>(0);

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTab(newValue);
        console.log(newValue);
    };

    const getPanelContent = () => {
        let panelContent = undefined;
        if (selectedProviderId && activeProviders?.length > 0) {
            const provider = activeProviders.find((provider: Provider) => provider.Id === selectedProviderId);
            dispatch(setSchedulingProvider(provider));
            switch (tab) {
                case 0: {
                    panelContent = <OfficeConfirmations selectedProviderId={selectedProviderId} />;
                    break;
                }
                default: {
                    panelContent = <OfficeConfirmations selectedProviderId={selectedProviderId} />;
                }
            }
        } else {
            panelContent = (
                <Box justifyContent={'center'} alignItems={'center'} display="flex" height="100vh" style={{ position: 'relative' }}>
                    <Typography align="center" style={{ position: 'absolute', top: '10%', left: '50%', transform: 'translateX(-50%)' }}>
                        No provider selected. You're either a CEO role and need to select a scheduler from the dropdown, or somehow the provider ID was not set.
                        (Try reloading)
                    </Typography>
                </Box>
            );
        }
        return panelContent;
    };

    const handleSchedulerChange = (event: any) => {
        setSelectedProviderId(event.target.value);
    };

    useEffect(() => {
        dispatch(getActiveProviders(false));
        let response = fetchProvidersUtil();
        response.then((providers) => {
            let tmpSchedulers = providers.filter((x) => x.Role.RoleName === 'Scheduling');
            console.log(tmpSchedulers);
            setSchedulers(tmpSchedulers);
        });

        if (authData.Role !== 'CEO') {
            setSelectedProviderId(authData.UserId);
        }
        document.title = props.title;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // render
    return (
        <>
            <Stack justifyContent={'center'} alignItems="center">
                {authData.Role === 'CEO' ? (
                    <FormControl variant="filled" style={{ textAlign: 'left', width: '300px' }}>
                        <InputLabel id="number-select-label">Select A Scheduler</InputLabel>
                        <Select value={selectedProviderId} labelId="number-select-label" id="number-select" onChange={handleSchedulerChange}>
                            {schedulers.map((scheduler) => (
                                <MenuItem value={scheduler.Id} key={scheduler.Id}>
                                    {scheduler.FullName}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                ) : null}
            </Stack>
            <Tabs
                variant="fullWidth"
                value={tab}
                indicatorColor="secondary"
                textColor="secondary"
                onChange={handleTabChange}
                aria-label="disabled tabs example"
                centered
            >
                <Tab label="Confirmations" />
            </Tabs>
            {getPanelContent()}
        </>
    );
};

export default OfficeSchedulingPanel;
