import { HedisMetricsTypeEnum } from '../../Enum/HedisMetricsTypeEnum';
// import { ClaimSourceEnum } from '../../Enum/ClaimSourceEnum';
import { AllAlerts } from '../HIE/AllAlerts.model';
import { HedisMetricStatusEnum } from '../../Enum/HedisMetricStatusEnum';

export default interface IHedisMetricsVisit {
    Id: string;
    DateLastMet: Date;
    HedisMetricType: HedisMetricsTypeEnum;
    IsExpired: boolean;
    IsMetAtThisVisit: boolean;
    ProviderId: string;
    ClaimNumber: string;
    CPTcode: string;
    ClaimSource: number;
    ClaimSourceName: string;
    ProviderName: string;
    Note: string;
    HedisAlert: AllAlerts;
    DocumentId: string;
    DocumentUri: string;
    DocumentName: string;
    DocumentMetaTag: string;
    DateUpdatedByHealthPlan: Date | null;
    MetricStatus: HedisMetricStatusEnum;
    File: any;
}

export class HedisMetricsVisit implements IHedisMetricsVisit {
    Id: string;
    DateLastMet: Date;
    HedisMetricType: HedisMetricsTypeEnum;
    IsExpired: boolean;
    IsMetAtThisVisit: boolean;
    ProviderId: string;
    ClaimNumber: string;
    CPTcode: string;
    ClaimSource: number;
    ClaimSourceName: string;
    ProviderName: string;
    Note: string;
    HedisAlert: AllAlerts;
    DocumentId: string;
    DocumentUri: string;
    DocumentName: string;
    DocumentMetaTag: string;
    DateUpdatedByHealthPlan: Date | null;
    MetricStatus: HedisMetricStatusEnum;
    File: any;

    constructor() {
        this.Id = null;
        this.DateLastMet = null;
        this.HedisMetricType = 0;
        this.IsExpired = false;
        this.IsMetAtThisVisit = false;
        this.ProviderId = '';
        this.ClaimNumber = '';
        this.CPTcode = '';
        this.ClaimSource = 0;
        this.ClaimSourceName = '';
        this.ProviderName = '';
        this.Note = '';
        this.DocumentId = '';
        this.DocumentUri = '';
        this.DocumentName = '';
        this.DocumentMetaTag = '';
        this.DateUpdatedByHealthPlan = null;
        this.MetricStatus = 0;
        this.File = null;
    }
}
