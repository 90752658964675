import { useEffect, useState } from 'react';
import { Box, Stack, Tab, Tabs, Tooltip } from '@mui/material';
import Alerts from './HIETabs/Alerts/Alerts';
import History from './HIETabs/History/History';
import { useDispatch } from 'react-redux';
import { clearSelections } from '../../store/hie.slice';
import InfoIcon from '@mui/icons-material/Info';
import HIEhelp from '../../assets/documents/HIEHelp.pdf';
import Loading from '../Shared/Loading';

const HiePage = (props) => {
    const dispatch = useDispatch();
    const [currentTab, setCurrentTab] = useState(0);
    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        dispatch(clearSelections());
        setCurrentTab(newValue);
    };
    useEffect(() => {
        document.title = props.title;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box>
            <Loading message="Loading.." />
            <Stack direction={'row'} alignItems={'center'} marginBottom={2}>
                <Tabs value={currentTab} onChange={handleTabChange}>
                    <Tab value={0} label="Alerts" />
                    <Tab value={1} label="History" />
                </Tabs>
                <Tooltip title="HIE Help Document">
                    <InfoIcon color="primary" fontSize="small" cursor="pointer" onClick={() => window.open(`${HIEhelp}`, '_blank')} />
                </Tooltip>
            </Stack>
            {currentTab === 0 && <Alerts />}
            {currentTab === 1 && <History />}
        </Box>
    );
};

export default HiePage;
