export enum CPTcodeCategoryEnum {
    General = 0,
    ECG = 1,
    IVHydration = 2,
    IVTherapeutic = 3,
    InitialVisit = 4,
    FollowUpVisit = 5,
    TelephonicFollowUp = 6,
    EvaluationAndManagement = 7,
    PsychotherapyServicesAndProcedures = 8,
    OtherPsychotherapy = 9,
    PhysicalMedicineAndRehabilitation = 10,
}
