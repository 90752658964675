import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { formatDateMMDDYYYY } from '../../../utils/timeFormat';

interface AppointmentDateRangeProps {
    startDate: Date;
    endDate: Date;
}

const AppointmentDateRange: React.FC<AppointmentDateRangeProps> = ({ startDate, endDate }) => (
    <Stack spacing={1}>
        <Typography style={{ textAlign: 'center' }} variant="body1">
            {`Unconfirmed appointments from ${formatDateMMDDYYYY(startDate)} to ${formatDateMMDDYYYY(endDate)}`}
        </Typography>
        <Typography style={{ textAlign: 'center' }} variant="body2" color="text.secondary">
            Select an appointment to view the information.
        </Typography>
    </Stack>
);

export default AppointmentDateRange;
