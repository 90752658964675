import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    Autocomplete,
    Box,
    Button,
    Chip,
    CircularProgress,
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputLabel,
    Link,
    List,
    ListItem,
    MenuItem,
    Paper,
    Popper,
    Select,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import AlertDialog from '../../AlertDialog/AlertDialog';
import { RootState } from '../../../reducers';

import {
    fetchCptCodeGroups,
    fetchDiagnosisCodesFromClaims,
    getCPTUnits,
    getDefaultDiagnosis,
    getEncounterFromSummaryNotes,
    getICD10DiagnosisByCategory,
    getICD10DiagnosisByKeyword,
    getPastDiagnosisByMember,
    insertEncounters,
    insertEncountersNotAsssessment,
    resetDisgnosysList,
    setDefaultDiagnosis,
    setEncounterList,
    updateDefaultDiagnosis,
    prepareICD10Hierarchy,
} from '../../../store/encounter.slice';
import { setSelectedMemberDetailPage } from '../../../store/memberDemographicsDetails.slice';
import { updateAppointmentIdListToStatus, resetDocumentEngagement } from '../../../store/documentEngagement.slice';
import { assessmentMenuEnum, assessmentStatusEnum, roleEnum } from '../../../utils/assessments';
import Authorization from '../../../utils/Authorization';
import { CPTcode } from '../../../Models/Shared/CPTCodeGroup';
import { copyObject, getCPTUserRole } from '../../../utils/common';
import { MemberEncounter } from '../../../Models/Member/MemberEncounter.model';
import { DiagnosisDraft, DiagnosisPointer } from '../../../Models/DocumentEngagements/DiagnosisDraft.model';
import { ICD10 } from '../../../Models/Shared/ICD10.model';
import moment from 'moment';
import { EncounterStatusEnum } from '../../../Enum/EncounterStatusEnum';
import { EncounterPlaceEnum } from '../../../Enum/EncounterPlaceEnum';
import { MeansOfEngagementEnum } from '../../../Enum/MeansOfEngagementEnum';
import { getMemberAllergies, setAssessmentError, resetAssessment } from '../../../store/assessment.slice';
import classes from '../Styles';
import { EncounterList } from '../../../Models/Encounters/EncounterList';
import { DiagnosisTypeEnum } from '../../../Enum/DiagnosisTypeEnum';
import { AppointmentStatusEnum } from '../../../Enum/AppointmentStatusEnum';
import { fetchTransitionalCareById } from '../../../store/transitionalCare.slice';
import { getDiagnosisByMember } from '../../../store/clinicalData.slice';
import { fetchTobaccoAndDrugAssessmentByMemberId, resetHraAssessment } from '../../../store/hra.slice';
import { resetFuhFumAssessment } from '../../../store/fuhfum.slice';
import { MemberStatusEnum } from '../../../Enum/MemberStatusEnum';
import { fetchGenders } from '../../../store/shared.slice';
import { saveGender, setMemberBasicInfo } from '../../../store/memberDetail.slice';

const PopperMy = function (props) {
    return <Popper {...props} style={{ maxWidth: 'fit-content' }} placement="bottom-start" />;
};

class DiagnosisFromClaimsState {
    Show: boolean;
    Index?: number;
    DxIndex?: number;
    ParentPropName: string;
    PropName: string;

    constructor() {
        this.Show = false;
        this.Index = null;
    }
}

export interface IProcedureSignOffProps {
    memberId: string;
    requestId?: string;
    disableWarnings?: boolean;
    noAppointments?: boolean;
    notAssessment?: boolean;
    isNotEncounterableOnly?: boolean;
    finalizeSignOff?: (notes: string) => void;
}

const defaultDiagnosisSchema = Yup.object().shape({
    Diagnosis: Yup.string().nullable().required('Primary diagnosis is required.'),
    FirstDiagnosis: Yup.object()
        .nullable()
        .when('DiagnosisFirstList', {
            is: (list: ICD10[]) => Boolean(list?.length),
            then: Yup.object()
                .nullable()
                .required('Diagnosis is required.')
                .shape({
                    Id: Yup.string().nullable().required('Diagnosis is required.'),
                }),
        }),
    SecondDiagnosis: Yup.object()
        .nullable()
        .when('DiagnosisSecondList', {
            is: (list: ICD10[]) => Boolean(list?.length),
            then: Yup.object()
                .nullable()
                .required('Diagnosis is required.')
                .shape({
                    Id: Yup.string().nullable().required('Diagnosis is required.'),
                }),
        }),
    ThirdDiagnosis: Yup.object()
        .nullable()
        .when('DiagnosisThirdList', {
            is: (list: ICD10[]) => Boolean(list?.length),
            then: Yup.object()
                .nullable()
                .required('Diagnosis is required.')
                .shape({
                    Id: Yup.string().nullable().required('Diagnosis is required.'),
                }),
        }),
    FourthDiagnosis: Yup.object()
        .nullable()
        .when('DiagnosisFourthList', {
            is: (list: ICD10[]) => Boolean(list?.length),
            then: Yup.object()
                .nullable()
                .required('Diagnosis is required.')
                .shape({
                    Id: Yup.string().nullable().required('Diagnosis is required.'),
                }),
        }),
});

const ProcedureSignOff: React.FC<IProcedureSignOffProps> = ({
    memberId,
    disableWarnings,
    noAppointments,
    notAssessment,
    isNotEncounterableOnly,
    finalizeSignOff,
}) => {
    const dispatch = useDispatch();
    const [focusInputName, setFocusInputName] = useState('');
    const [clickedBtnId, setClickedBtnId] = useState('');
    const [showCptErrorMsg, setShowCptErrorMsg] = useState(false);
    const [showDefaultDiagnosisModal, setShowDefaultDiagnosisModal] = useState<boolean>(false);
    const [showWarnings, setShowWarnings] = useState(true);
    const [showDxError, setShowDxError] = useState(false);
    const [assessmentWarnings, setAssessmentWarnings] = useState([]);
    const [diagnosisFromClaims, setDiagnosisFromClaims] = useState<DiagnosisFromClaimsState>(new DiagnosisFromClaimsState());
    const {
        cptCodeCategories,
        cptUnits,
        encounterList,
        diagnosisFromClaims: claimsDiagnosis,
        memberPastDiagnosys,
        memberDefaultDiagnosis,
        isFetchingDisgnosys,
        diagnosys,
        isFetching,
        isSaving,
        isSavingDefaultDiagnosis,
    } = useSelector((state: RootState) => state.encounter);
    const { diagnosises, isDiagnosisFetching } = useSelector((state: RootState) => state.clinicalData);
    const { genders, isLoading } = useSelector((state: RootState) => state.shared);
    const { selectedRole, memberAllergies } = useSelector((state: RootState) => state.assessment);
    const { engagementDraft, memberAssessmentHistory } = useSelector((state: RootState) => state.documentEngagement);
    const { memberTransitionalCare } = useSelector((state: RootState) => state.transitionalCare);
    const { memberBasicInfo } = useSelector((state: RootState) => state.memberDetail);
    const { tobaccoAndDrug } = useSelector((state: RootState) => state.hra);
    const diagnosisFromClaimsId = 'DiagnosisFromClaims';
    const authData = new Authorization();
    const isInHra = selectedRole === roleEnum.HRA;
    const isHraMember = memberBasicInfo?.IsInHra;
    const isNursePractitioner = authData.Role === roleEnum.NURSE_PRACTITIONER;
    const isCoder = authData.Role === roleEnum.CODER;
    const diagnosisesByFilter = diagnosises.map((d) => {
        return { Id: d.Id, Code: d.Code, Name: d.Name, ICDcodeVersion: d.ICDcodeVersion } as ICD10;
    });
    const memberPastDiagnosysByFilter = memberPastDiagnosys.filter((d) => !(diagnosisesByFilter || []).some((cd) => cd.Code === d.Code));
    const claimsDiagnosisByFilter = claimsDiagnosis.filter(
        (d) => ![...(diagnosisesByFilter || []), ...memberPastDiagnosysByFilter].some((cd) => cd.Code === d.Code)
    );
    const [showDiagnosisAlert, setShowDiagnosisAlert] = useState(memberBasicInfo?.Diagnosis?.some((m) => m.Code === 'E10' || m.Code === 'E11'));
    const isNotEncounterable = engagementDraft.SummaryNotes.some((note) => note.IsNotEncounterable);

    useEffect(() => {
        dispatch(fetchGenders());
        dispatch(getEncounterFromSummaryNotes(memberId));
        dispatch(getDefaultDiagnosis(memberId));
        dispatch(fetchCptCodeGroups(isInHra));
        dispatch(getMemberAllergies(memberId));
        if (Boolean(engagementDraft?.TransitionalCareId)) {
            dispatch(fetchTransitionalCareById(engagementDraft.TransitionalCareId));
        }
        dispatch(getCPTUnits());
        dispatch(fetchDiagnosisCodesFromClaims(memberId, engagementDraft?.AssessedRole?.Id));
        dispatch(getDiagnosisByMember(memberId, engagementDraft?.AssessedRole?.Id));
        dispatch(resetDisgnosysList());
        dispatch(getPastDiagnosisByMember(memberId, engagementDraft?.AssessedRole?.Id));
        if (!Boolean(engagementDraft?.TobaccoAndDrugAssessmentId)) {
            dispatch(fetchTobaccoAndDrugAssessmentByMemberId(memberId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!disableWarnings) {
            prepareWarnings();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [memberTransitionalCare, memberAllergies]);

    const prepareCPTCodes = (categoryGroupId: string) => {
        let cptCodes = [];
        let cptUserRole = getCPTUserRole(selectedRole);
        cptCodes = (
            cptCodeCategories
                .find((c) => c.Id === categoryGroupId)
                ?.CPTRoleCodes.find(
                    (c) =>
                        c.Role === cptUserRole ||
                        (isHraMember &&
                            ((isInHra && (authData.Role === roleEnum.NURSE_PRACTITIONER || authData.Role === roleEnum.BEHAVIOURAL_HEALTH_SPECIALIST)) ||
                                selectedRole === roleEnum.NURSE_PRACTITIONER ||
                                selectedRole === roleEnum.BEHAVIOURAL_HEALTH_SPECIALIST) &&
                            c.Role === 'HRA')
                )?.Codes || []
        ).map((item) => {
            return { ...item, Name: `${item.Code} - ${item.Description}` };
        });

        return cptCodes as CPTcode[];
    };

    const filteredCPTCodes = (categoryGroupId: string) => {
        let cptCodes = prepareCPTCodes(categoryGroupId);
        cptCodes = cptCodes.filter((c) => c.Code !== '83036');
        return cptCodes as CPTcode[];
    };

    const prepareCPTUnits = () => {
        return cptUnits.filter((c) => c.Code === 'T1016');
    };

    const handleOnChangeCpt = (val: any, propName: string, index: number) => {
        let data = copyObject(encounterList);
        data.Encounters[index][propName] = val;
        if (propName === 'CategoryGroupId') {
            data.Encounters[index].CptCodeId = null;
        }

        dispatch(setEncounterList(data));
    };

    const handleOnChangeCptCode = (val: any, propName: string, index: number, cptCategoryId: string) => {
        let data = copyObject(encounterList);
        let filterCptCodeList = filteredCPTCodes(cptCategoryId);
        let cptCodeDate = filterCptCodeList.find((c) => c.Id === val);
        if (cptCodeDate) {
            data.Encounters[index]['CptCode'] = cptCodeDate.Code;
        }
        data.Encounters[index][propName] = val;
        if (propName === 'CptCodeId') {
            data.Encounters[index]['Units'] = 1;
        }

        if (propName === 'CategoryGroupId') {
            data.Encounters[index].CptCodeId = null;
        }

        dispatch(setEncounterList(data));
    };

    const handleOnChangePrimaryDx = (val: ICD10, propName: string, index: number, dxIndex: number) => {
        const fieldValue = Boolean(val) ? `${val.Code} ${val.Name}` : '';
        let data = copyObject(encounterList);
        data.Encounters[index].DiagnosisList[dxIndex][propName] = fieldValue;
        const selectedDiagnosis = Boolean(val) ? diagnosys.find((d) => d.Code === val.Code) : null;
        data.Encounters[index].DiagnosisList[dxIndex].DiagnosisPointer = selectedDiagnosis;
        data.Encounters[index].DiagnosisList[dxIndex].DiagnosisFirstList = [];
        data.Encounters[index].DiagnosisList[dxIndex].DiagnosisSecondList = [];
        data.Encounters[index].DiagnosisList[dxIndex].DiagnosisThirdList = [];
        data.Encounters[index].DiagnosisList[dxIndex].DiagnosisFourthList = [];
        data.Encounters[index].DiagnosisList[dxIndex].FirstDiagnosis = null;
        data.Encounters[index].DiagnosisList[dxIndex].SecondDiagnosis = null;
        data.Encounters[index].DiagnosisList[dxIndex].ThirdDiagnosis = null;
        data.Encounters[index].DiagnosisList[dxIndex].FourthDiagnosis = null;

        dispatch(setEncounterList(data, true));
    };

    const handleOnChangeDx = (val: any, propName: string, index: number, dxIndex: number, diagnosis?: ICD10) => {
        let data = copyObject(encounterList);
        data.Encounters[index].DiagnosisList[dxIndex][propName] = val;
        if (Boolean(diagnosis)) {
            data.Encounters[index].DiagnosisList[dxIndex].DiagnosisPointer = diagnosis;
        }

        if (propName === 'FirstDiagnosis') {
            data.Encounters[index].DiagnosisList[dxIndex].DiagnosisSecondList = [];
            data.Encounters[index].DiagnosisList[dxIndex].DiagnosisThirdList = [];
            data.Encounters[index].DiagnosisList[dxIndex].DiagnosisFourthList = [];
            data.Encounters[index].DiagnosisList[dxIndex].SecondDiagnosis = null;
            data.Encounters[index].DiagnosisList[dxIndex].ThirdDiagnosis = null;
            data.Encounters[index].DiagnosisList[dxIndex].FourthDiagnosis = null;
        }
        if (propName === 'SecondDiagnosis') {
            data.Encounters[index].DiagnosisList[dxIndex].DiagnosisThirdList = [];
            data.Encounters[index].DiagnosisList[dxIndex].DiagnosisFourthList = [];
            data.Encounters[index].DiagnosisList[dxIndex].ThirdDiagnosis = null;
            data.Encounters[index].DiagnosisList[dxIndex].FourthDiagnosis = null;
        }
        if (propName === 'ThridDiagnosis') {
            data.Encounters[index].DiagnosisList[dxIndex].DiagnosisFourthList = [];
            data.Encounters[index].DiagnosisList[dxIndex].FourthDiagnosis = null;
        }

        dispatch(setEncounterList(data));
    };

    const handleOnChangeDefaultDiagnosis = (val: ICD10, nextListPropName: string) => {
        const fieldValue = Boolean(val) ? `${val.Code} ${val.Name}` : '';
        let data = copyObject(memberDefaultDiagnosis.DefaultDiagnosis) as DiagnosisDraft;
        data.Diagnosis = fieldValue;
        const selectedDiagnosis = Boolean(val) ? diagnosys.find((d) => d.Code === val.Code) : null;
        if (Boolean(selectedDiagnosis)) {
            data.DiagnosisPointer = {
                Id: selectedDiagnosis.Id,
                Name: selectedDiagnosis.Name,
                Code: selectedDiagnosis.Code,
                ICDcodeVersion: selectedDiagnosis.ICDcodeVersion,
                DiagnosisType: DiagnosisTypeEnum.Primary,
            } as DiagnosisPointer;
        } else {
            data.DiagnosisPointer = null;
        }
        data.DiagnosisFirstList = [];
        data.DiagnosisSecondList = [];
        data.DiagnosisThirdList = [];
        data.DiagnosisFourthList = [];
        data.FirstDiagnosis = null;
        data.SecondDiagnosis = null;
        data.ThirdDiagnosis = null;
        data.FourthDiagnosis = null;

        dispatch(setDefaultDiagnosis({ ...memberDefaultDiagnosis, DefaultDiagnosis: data }));
        if (Boolean(val?.Code)) {
            dispatch(getICD10DiagnosisByCategory(val.Code, 0, 0, nextListPropName, true));
        }
    };

    const handleOnChangeDifalultDx = (val: any, propName: string, nextListPropName: string = '') => {
        let data = copyObject(memberDefaultDiagnosis.DefaultDiagnosis) as DiagnosisDraft;
        data[propName] = val;

        if (propName === 'FirstDiagnosis') {
            data.DiagnosisSecondList = [];
            data.DiagnosisThirdList = [];
            data.DiagnosisFourthList = [];
            data.SecondDiagnosis = null;
            data.ThirdDiagnosis = null;
            data.FourthDiagnosis = null;
        }
        if (propName === 'SecondDiagnosis') {
            data.DiagnosisThirdList = [];
            data.DiagnosisFourthList = [];
            data.ThirdDiagnosis = null;
            data.FourthDiagnosis = null;
        }
        if (propName === 'ThridDiagnosis') {
            data.DiagnosisFourthList = [];
            data.FourthDiagnosis = null;
        }

        if (Boolean(nextListPropName)) {
            data[nextListPropName] = [];
            if (Boolean(val?.Code)) {
                dispatch(getICD10DiagnosisByCategory(val.Code, 0, 0, nextListPropName, true));
            }
        }
        dispatch(setDefaultDiagnosis({ ...memberDefaultDiagnosis, DefaultDiagnosis: data }));
    };

    const handleOnClickAdd = () => {
        let data = copyObject(encounterList);
        let tempId = (Math.random() + 1).toString(36).substring(7);
        data.Encounters.push({ tempId, DiagnosisList: [new DiagnosisDraft()] });
        dispatch(setEncounterList(data));
    };

    const handleOnClickRemove = (index) => {
        let data = copyObject(encounterList);
        data.Encounters = data.Encounters.filter((item, i) => i !== index);
        dispatch(setEncounterList(data));
    };

    const handleOnClickAddDiagnosis = (index: number) => {
        let data = copyObject(encounterList) as EncounterList;
        if (data.Encounters[index].DiagnosisList?.some((d) => !d.Diagnosis)) {
            dispatch(setAssessmentError({ Show: true, Message: 'Please select a diagnosis' }));
            return;
        }
        data.Encounters[index].DiagnosisList.push(new DiagnosisDraft());
        dispatch(setEncounterList(data));
    };

    const handleOnClickRemoveDiagnosis = (index: number, dxIndex: number) => {
        let data = copyObject(encounterList);
        data.Encounters[index].DiagnosisList = data.Encounters[index].DiagnosisList.filter((_, i) => i !== dxIndex);
        dispatch(setEncounterList(data));
    };

    const validateCpt = () => {
        if (encounterList.Encounters.some((e) => !Boolean(e.CptCodeId))) {
            dispatch(setAssessmentError({ Show: true, Message: 'Please add a primary CPT code to all encounters before submitting.' }));
            return false;
        }

        if (encounterList.Encounters.some((e) => !Boolean(e.Units))) {
            dispatch(setAssessmentError({ Show: true, Message: "Please verify you've selected Units." }));
            return false;
        }

        return true;
    };

    const handleOnSave = (e, isReview = false) => {
        if (!memberBasicInfo.Gender || memberBasicInfo.Gender === genders.find((x) => x.value === '0').label) {
            dispatch(setAssessmentError({ Show: true, Message: 'Please select a Male or Female gender for claim submission.' }));
            return;
        }

        if (!validateCpt()) {
            return;
        } else if (!isReview) {
            setShowCptErrorMsg(false);
            if (
                authData.Permissions.includes('PHQ9 Score Greater Than 14 Alert') &&
                encounterList.Encounters.some((en) => !Boolean(en.PrimaryDiagnosis?.Diagnosis))
            ) {
                dispatch(setAssessmentError({ Show: true, Message: 'Please select a diagnosis' }));
                return;
            }
        }

        let isDiagnosisMissed = false;
        encounterList.Encounters.forEach((encounter) => {
            encounter.DiagnosisList.forEach((diagnosis) => {
                if (Boolean(diagnosis.DiagnosisFirstList?.length) && !Boolean(diagnosis.FirstDiagnosis)) {
                    isDiagnosisMissed = true;
                }
                if (Boolean(diagnosis.DiagnosisSecondList?.length) && !Boolean(diagnosis.SecondDiagnosis)) {
                    isDiagnosisMissed = true;
                }
                if (Boolean(diagnosis.DiagnosisThirdList?.length) && !Boolean(diagnosis.ThirdDiagnosis)) {
                    isDiagnosisMissed = true;
                }
                if (Boolean(diagnosis.DiagnosisFourthList?.length) && !Boolean(diagnosis.FourthDiagnosis)) {
                    isDiagnosisMissed = true;
                }
            });
        });

        if (!isReview && isDiagnosisMissed) {
            setShowDxError(true);
            dispatch(setAssessmentError({ Show: true, Message: 'Please select a diagnosis' }));
            return;
        }

        let canSubmit = true;
        let encounters = [] as MemberEncounter[];
        encounterList.Encounters.forEach((encounter) => {
            const cptCodeDetail = prepareCPTCodes(encounter.CategoryGroupId).find((c) => c.Id === encounter.CptCodeId);
            var primaryDiagPointer = [] as DiagnosisPointer[];
            if (Boolean(encounter.PrimaryDiagnosis)) {
                if (Boolean(encounter.PrimaryDiagnosis?.DiagnosisPointer?.Id)) {
                    primaryDiagPointer.push(encounter.PrimaryDiagnosis.DiagnosisPointer);
                }
                if (Boolean(encounter.PrimaryDiagnosis?.FirstDiagnosis?.Id)) {
                    primaryDiagPointer.push(convertICD10ToDiagnosisPointer(encounter.PrimaryDiagnosis.FirstDiagnosis));
                }
                if (Boolean(encounter.PrimaryDiagnosis?.SecondDiagnosis?.Id)) {
                    primaryDiagPointer.push(convertICD10ToDiagnosisPointer(encounter.PrimaryDiagnosis.SecondDiagnosis));
                }
                if (Boolean(encounter.PrimaryDiagnosis?.ThirdDiagnosis?.Id)) {
                    primaryDiagPointer.push(convertICD10ToDiagnosisPointer(encounter.PrimaryDiagnosis.ThirdDiagnosis));
                }
                if (Boolean(encounter.PrimaryDiagnosis?.FourthDiagnosis?.Id)) {
                    primaryDiagPointer.push(convertICD10ToDiagnosisPointer(encounter.PrimaryDiagnosis.FourthDiagnosis));
                }
            } else {
                canSubmit = false;
            }

            var secondaryDiagPointer = [] as DiagnosisPointer[];
            if (Boolean(encounter.SecondaryDiagnosis)) {
                if (Boolean(encounter.SecondaryDiagnosis?.DiagnosisPointer?.Id)) {
                    secondaryDiagPointer.push(encounter.SecondaryDiagnosis.DiagnosisPointer);
                }
                if (Boolean(encounter.SecondaryDiagnosis?.FirstDiagnosis?.Id)) {
                    secondaryDiagPointer.push(convertICD10ToDiagnosisPointer(encounter.SecondaryDiagnosis.FirstDiagnosis));
                }
                if (Boolean(encounter.SecondaryDiagnosis?.SecondDiagnosis?.Id)) {
                    secondaryDiagPointer.push(convertICD10ToDiagnosisPointer(encounter.SecondaryDiagnosis.SecondDiagnosis));
                }
                if (Boolean(encounter.SecondaryDiagnosis?.ThirdDiagnosis?.Id)) {
                    secondaryDiagPointer.push(convertICD10ToDiagnosisPointer(encounter.SecondaryDiagnosis.ThirdDiagnosis));
                }
                if (Boolean(encounter.SecondaryDiagnosis?.FourthDiagnosis?.Id)) {
                    secondaryDiagPointer.push(convertICD10ToDiagnosisPointer(encounter.SecondaryDiagnosis.FourthDiagnosis));
                }
            }

            var tertiaryDiagPointer = [] as DiagnosisPointer[];
            if (Boolean(encounter.TertiaryDiagnosis)) {
                if (Boolean(encounter.TertiaryDiagnosis?.DiagnosisPointer?.Id)) {
                    tertiaryDiagPointer.push(encounter.TertiaryDiagnosis.DiagnosisPointer);
                }
                if (Boolean(encounter.TertiaryDiagnosis?.FirstDiagnosis?.Id)) {
                    tertiaryDiagPointer.push(convertICD10ToDiagnosisPointer(encounter.TertiaryDiagnosis.FirstDiagnosis));
                }
                if (Boolean(encounter.TertiaryDiagnosis?.SecondDiagnosis?.Id)) {
                    tertiaryDiagPointer.push(convertICD10ToDiagnosisPointer(encounter.TertiaryDiagnosis.SecondDiagnosis));
                }
                if (Boolean(encounter.TertiaryDiagnosis?.ThirdDiagnosis?.Id)) {
                    tertiaryDiagPointer.push(convertICD10ToDiagnosisPointer(encounter.TertiaryDiagnosis.ThirdDiagnosis));
                }
                if (Boolean(encounter.TertiaryDiagnosis?.FourthDiagnosis?.Id)) {
                    tertiaryDiagPointer.push(convertICD10ToDiagnosisPointer(encounter.TertiaryDiagnosis.FourthDiagnosis));
                }
            }

            var quaternaryDiagPointer = [] as DiagnosisPointer[];
            if (Boolean(encounter.QuaternaryDiagnosis)) {
                if (Boolean(encounter.QuaternaryDiagnosis?.DiagnosisPointer?.Id)) {
                    quaternaryDiagPointer.push(encounter.QuaternaryDiagnosis.DiagnosisPointer);
                }
                if (Boolean(encounter.QuaternaryDiagnosis?.FirstDiagnosis?.Id)) {
                    quaternaryDiagPointer.push(convertICD10ToDiagnosisPointer(encounter.QuaternaryDiagnosis.FirstDiagnosis));
                }
                if (Boolean(encounter.QuaternaryDiagnosis?.SecondDiagnosis?.Id)) {
                    quaternaryDiagPointer.push(convertICD10ToDiagnosisPointer(encounter.QuaternaryDiagnosis.SecondDiagnosis));
                }
                if (Boolean(encounter.QuaternaryDiagnosis?.ThirdDiagnosis?.Id)) {
                    quaternaryDiagPointer.push(convertICD10ToDiagnosisPointer(encounter.QuaternaryDiagnosis.ThirdDiagnosis));
                }
                if (Boolean(encounter.QuaternaryDiagnosis?.FourthDiagnosis?.Id)) {
                    quaternaryDiagPointer.push(convertICD10ToDiagnosisPointer(encounter.QuaternaryDiagnosis.FourthDiagnosis));
                }
            }

            var additionalDiagnosis = [] as DiagnosisPointer[];
            if (Boolean(encounter.AdditionalDiagnosis)) {
                encounter.AdditionalDiagnosis.forEach((diagnosis) => {
                    let diagnosisPointer = {} as DiagnosisPointer;
                    if (diagnosis.FourthDiagnosis != null) {
                        diagnosisPointer = convertICD10ToDiagnosisPointer(diagnosis.FourthDiagnosis);
                    } else if (diagnosis.ThirdDiagnosis != null) {
                        diagnosisPointer = convertICD10ToDiagnosisPointer(diagnosis.ThirdDiagnosis);
                    } else if (diagnosis.SecondDiagnosis != null) {
                        diagnosisPointer = convertICD10ToDiagnosisPointer(diagnosis.SecondDiagnosis);
                    } else if (diagnosis.FirstDiagnosis != null) {
                        diagnosisPointer = convertICD10ToDiagnosisPointer(diagnosis.FirstDiagnosis);
                    } else {
                        diagnosisPointer = diagnosis.DiagnosisPointer;
                    }
                    additionalDiagnosis.push(diagnosisPointer);
                });
            }

            var encounterDetails = {} as MemberEncounter;
            encounterDetails.Id = encounter.Id;
            encounterDetails.MemberId = memberId;
            encounterDetails.EncounterPlace = getEncounterPlace(engagementDraft.MeansOfEngagement);
            encounterDetails.CategoryGroupId = encounter.CategoryGroupId;
            encounterDetails.Units = Boolean(encounter.Units) ? encounter.Units : 1;
            encounterDetails.CPTcode = cptCodeDetail;
            encounterDetails.PrimaryDiagnosisList = primaryDiagPointer.length > 0 ? primaryDiagPointer : null;
            encounterDetails.SecondaryDiagnosisList = secondaryDiagPointer.length > 0 ? secondaryDiagPointer : null;
            encounterDetails.TertiaryDiagnosisList = tertiaryDiagPointer.length > 0 ? tertiaryDiagPointer : null;
            encounterDetails.QuaternaryDiagnosisList = quaternaryDiagPointer.length > 0 ? quaternaryDiagPointer : null;
            encounterDetails.ServiceDate = moment(engagementDraft.VisitedTime).format('MM/DD/yyyy hh:mm');
            encounterDetails.AdditionalDiagnosisList = additionalDiagnosis;
            encounterDetails.IsSummaryNote = encounter.IsSummaryNote;
            encounterDetails.EncounterStatus = isReview ? EncounterStatusEnum.ForReview : EncounterStatusEnum.ToSubmit;
            encounters.push(encounterDetails);
        });

        if (!isReview && !canSubmit) {
            dispatch(setAssessmentError({ Show: true, Message: 'Please add a primary diagnosis to all encounters before submitting.' }));
            return;
        } else if (encounters.some((e) => !Boolean(e.CPTcode))) {
            dispatch(setAssessmentError({ Show: true, Message: 'Please add a primary CPT code to all encounters before submitting.' }));
            return;
        }

        if (!noAppointments) {
            if (engagementDraft.Contacted) {
                dispatch(updateAppointmentIdListToStatus(AppointmentStatusEnum.Completed, null));
            } else {
                dispatch(updateAppointmentIdListToStatus(AppointmentStatusEnum.NoCallOrNoShow, null));
            }
        }

        if (notAssessment) {
            dispatch(insertEncountersNotAsssessment({ MemberEncounter: encounters }, false, finalizeSignOff));
        } else {
            dispatch(
                insertEncounters({ MemberEncounter: encounters }, false, () => {
                    navigateToDocumentEngagement();
                })
            );
        }

        setClickedBtnId(e.target.id);
    };

    const navigateToDocumentEngagement = () => {
        dispatch(resetAssessment());
        dispatch(resetHraAssessment());
        dispatch(resetFuhFumAssessment());
        dispatch(resetDocumentEngagement());
        dispatch(setSelectedMemberDetailPage('Engagement'));
    };

    const getEncounterPlace = (meansOfEngagement: MeansOfEngagementEnum) => {
        switch (meansOfEngagement) {
            case MeansOfEngagementEnum.InPerson:
                return EncounterPlaceEnum.Home;
            case MeansOfEngagementEnum.Phone:
                return EncounterPlaceEnum.Phone;
            case MeansOfEngagementEnum.Telemedicine:
                return EncounterPlaceEnum.Telemedicine;
            case MeansOfEngagementEnum.Email:
                return EncounterPlaceEnum.Email;
            case MeansOfEngagementEnum.Office:
                return EncounterPlaceEnum.OfficeVisit;
            case MeansOfEngagementEnum.TextMessage:
                return EncounterPlaceEnum.TextMessage;
            default:
                return EncounterPlaceEnum.Home;
        }
    };

    const convertICD10ToDiagnosisPointer = (data: ICD10) => {
        return {
            Id: data.Id,
            Name: data.Name,
            ICDcodeVersion: data.ICDcodeVersion,
            Code: data.Code,
        } as DiagnosisPointer;
    };

    const handleOnSkipEncounters = (e) => {
        if (noAppointments || notAssessment) {
            dispatch(insertEncountersNotAsssessment({}, true, finalizeSignOff));
        } else {
            let statusChange = AppointmentStatusEnum.Completed;
            if (!engagementDraft.Contacted) {
                statusChange = AppointmentStatusEnum.NoCallOrNoShow;
            }
            dispatch(
                updateAppointmentIdListToStatus(statusChange, () => {
                    dispatch(
                        insertEncounters({}, true, () => {
                            navigateToDocumentEngagement();
                        })
                    );
                })
            );
        }

        setClickedBtnId(e.target.id);
    };

    const prepareDiagnosis = (index) => {
        return [
            { Id: diagnosisFromClaimsId, Code: 'Add Diagnosis from Claims', Name: '' },
            ...diagnosys.filter((d) => {
                const val = `${d.Code} ${d.Name}`;
                if (encounterList.Encounters[index]?.DiagnosisList.some((d) => d.Diagnosis === val)) {
                    return false;
                } else {
                    return true;
                }
            }),
        ];
    };

    const handleOnClickSetDefaultPrimary = (index: number) => {
        let data = copyObject(encounterList);
        data.Encounters[index].DiagnosisList[0] = memberDefaultDiagnosis.DefaultDiagnosis;
        dispatch(setEncounterList(data));
    };

    const handleOnChangeSelect = (val, index, dxIndex, bindResponsePropName) => {
        if (Boolean(val)) {
            dispatch(getICD10DiagnosisByCategory(val.Code, index, dxIndex, bindResponsePropName));
        }
    };

    const prepareWarnings = () => {
        let assessmentDetails = [];
        const authData = new Authorization();
        const noteCounts = engagementDraft?.SummaryNotes?.filter((s) => s.IsConfirm)?.length || 0;
        // const isDisCharged = Boolean(memberTransitionalCare?.Discharge?.Id) && memberBasicInfo?.IsInTransition;
        const discharges = memberTransitionalCare?.TransitionSteps?.filter((step) => step.TransitionStepType === 'Discharge');
        const isDisCharged = Boolean(discharges.length > 0) && memberBasicInfo?.IsInTransition;

        if (selectedRole === roleEnum.NURSE_PRACTITIONER || selectedRole === roleEnum.CEO || selectedRole === roleEnum.ADMIN) {
            if (!engagementDraft.ConsentFormId) assessmentDetails.push('Consent form is not completed');
            if (memberAssessmentHistory.VitalSignStatus !== assessmentStatusEnum.COMPLETED) {
                assessmentDetails.push('Vital Signs is not completed');
            }
            if (memberAssessmentHistory.ShowCarePlanAlert === true) {
                assessmentDetails.push('Plans are expired');
            } else if (engagementDraft.GoalStatus === assessmentStatusEnum.IN_PROGRESS) {
                assessmentDetails.push('Set goal is not completed');
            }
            if (memberAssessmentHistory.ShowSdohAlert === true || memberAssessmentHistory.SdohStatus === assessmentStatusEnum.IN_PROGRESS) {
                assessmentDetails.push('SDOH is not completed');
            }
            if (memberAssessmentHistory.PastPresentHistoryStatus === assessmentStatusEnum.IN_PROGRESS) {
                assessmentDetails.push('Past present history is not completed');
            }
            if (memberAssessmentHistory.PastSurgicalHistoryStatus === assessmentStatusEnum.IN_PROGRESS) {
                assessmentDetails.push('Past surgical history is not completed');
            }
            if (memberAssessmentHistory.ReviewSystemStatus === assessmentStatusEnum.IN_PROGRESS) {
                assessmentDetails.push('Review system is not completed');
            }
            if (memberAssessmentHistory.PhysicalExamStatus === assessmentStatusEnum.IN_PROGRESS) {
                assessmentDetails.push('Physical exam is not completed');
            }
            if (memberAssessmentHistory.ShowPhqAlert === true || memberAssessmentHistory.PhqStatus === assessmentStatusEnum.IN_PROGRESS) {
                assessmentDetails.push('PHQ9 is not completed');
            }
            if (
                memberAssessmentHistory.HgbA1CStatus === assessmentStatusEnum.IN_PROGRESS ||
                (memberAssessmentHistory.IsHgbA1CNeedsReview && authData.Role === roleEnum.NURSE_PRACTITIONER)
            ) {
                assessmentDetails.push('HgbA1C assessment is not completed');
            }
            if (memberAssessmentHistory.DiabetesFootStatus === assessmentStatusEnum.IN_PROGRESS) {
                assessmentDetails.push('Diabetes foot exam is not completed');
            }
            if (
                memberAssessmentHistory.UrinalysisReportStatus === assessmentStatusEnum.IN_PROGRESS ||
                (memberAssessmentHistory.IsUrinalysisNeedsReview && authData.Role === roleEnum.NURSE_PRACTITIONER)
            ) {
                assessmentDetails.push('Urinalysis report is not completed');
            }
            if (
                selectedRole === roleEnum.NURSE_PRACTITIONER &&
                (engagementDraft.AllergyStatus === assessmentStatusEnum.IN_PROGRESS || (memberAllergies?.length === 0 && memberBasicInfo?.NoKnownAllergies))
            ) {
                assessmentDetails.push('Allergy is not completed');
            }
            if (
                engagementDraft.MedicationReconciliationStatus === assessmentStatusEnum.IN_PROGRESS ||
                (isDisCharged && engagementDraft.MedicationReconciliationStatus !== assessmentStatusEnum.COMPLETED)
            ) {
                assessmentDetails.push('Medication reconciliation is not completed');
            }
            if (engagementDraft.ProgressNoteStatus !== assessmentStatusEnum.COMPLETED) {
                assessmentDetails.push('Progress notes are not completed');
            }
            if (!engagementDraft.FollowupRecommendations) {
                assessmentDetails.push('Follow up recommendations are not completed');
            }
            if (noteCounts === 0) {
                assessmentDetails.push('Summary notes are not selected');
            }
        } else if (selectedRole === roleEnum.CARE_COORDINATOR || selectedRole === roleEnum.COMMUNITY_HEALTH_WORKER) {
            if (!engagementDraft.ConsentFormId) assessmentDetails.push('Consent form is not completed');
            if (memberAssessmentHistory.VitalSignStatus !== assessmentStatusEnum.COMPLETED) {
                assessmentDetails.push('Vitalsign is not completed');
            }
            if (memberAssessmentHistory.ShowCarePlanAlert === true) {
                assessmentDetails.push('Plans are expired');
            } else if (engagementDraft.GoalStatus === assessmentStatusEnum.IN_PROGRESS) {
                assessmentDetails.push('Set goal is not completed');
            }
            if (memberAssessmentHistory.ShowSdohAlert === true || memberAssessmentHistory.SdohStatus === assessmentStatusEnum.IN_PROGRESS) {
                assessmentDetails.push('SDOH is not completed');
            }
            if (memberAssessmentHistory.ShowPhqAlert === true || memberAssessmentHistory.PhqStatus === assessmentStatusEnum.IN_PROGRESS) {
                assessmentDetails.push('PHQ9 is not completed');
            }
            if (
                memberAssessmentHistory.HgbA1CStatus === assessmentStatusEnum.IN_PROGRESS ||
                (memberAssessmentHistory.IsHgbA1CNeedsReview && authData.Role === roleEnum.NURSE_PRACTITIONER)
            ) {
                assessmentDetails.push('HgbA1C assessment is not completed');
            }
            if (
                memberAssessmentHistory.UrinalysisReportStatus === assessmentStatusEnum.IN_PROGRESS ||
                (memberAssessmentHistory.IsUrinalysisNeedsReview && authData.Role === roleEnum.NURSE_PRACTITIONER)
            ) {
                assessmentDetails.push('Urinalysis report is not completed');
            }
            if (memberAssessmentHistory.ShowCarePlanAlert === true || engagementDraft.CarePlanStatus === assessmentStatusEnum.IN_PROGRESS) {
                assessmentDetails.push('Integrated care plan are not completed');
            }
            if (engagementDraft.RequestStatus === assessmentStatusEnum.IN_PROGRESS) {
                assessmentDetails.push('Request is not completed');
            }
            if (engagementDraft.MedicationReconciliationStatus === assessmentStatusEnum.IN_PROGRESS) {
                assessmentDetails.push('Medication reconciliation is not completed');
            }
            if (engagementDraft.ProgressNoteStatus !== assessmentStatusEnum.COMPLETED) {
                assessmentDetails.push('Progress notes are not completed');
            }
            if (!engagementDraft.FollowupRecommendations) {
                assessmentDetails.push('Follow up recommendations are not completed');
            }
            if (noteCounts === 0) {
                assessmentDetails.push('Summary notes are not selected');
            }
        } else if (selectedRole === roleEnum.CLINICAL_LIAISON) {
            if (!engagementDraft.ConsentFormId) assessmentDetails.push('Consent form is not completed');
            if (memberAssessmentHistory.VitalSignStatus !== assessmentStatusEnum.COMPLETED) {
                assessmentDetails.push('Vitalsign is not completed');
            }
            if (memberAssessmentHistory.ShowCarePlanAlert === true) {
                assessmentDetails.push('Plans are expired');
            } else if (engagementDraft.GoalStatus === assessmentStatusEnum.IN_PROGRESS) {
                assessmentDetails.push('Set goal is not completed');
            }
            if (memberAssessmentHistory.ShowSdohAlert === true || memberAssessmentHistory.SdohStatus === assessmentStatusEnum.IN_PROGRESS) {
                assessmentDetails.push('SDOH is not completed');
            }
            if (engagementDraft.EducationStatus === assessmentStatusEnum.IN_PROGRESS) {
                assessmentDetails.push('Education is not completed');
            }
            if (
                engagementDraft.MedicationReconciliationStatus === assessmentStatusEnum.IN_PROGRESS ||
                (isDisCharged && engagementDraft.MedicationReconciliationStatus !== assessmentStatusEnum.COMPLETED)
            ) {
                assessmentDetails.push('Medication reconciliation is not completed');
            }
            if (engagementDraft.ProgressNoteStatus !== assessmentStatusEnum.COMPLETED) {
                assessmentDetails.push('Progress notes are not completed');
            }
            if (!engagementDraft.FollowupRecommendations) {
                assessmentDetails.push('Follow up recommendations are not completed');
            }
            if (noteCounts === 0) {
                assessmentDetails.push('Summary notes are not selected');
            }
        } else if (selectedRole === roleEnum.BEHAVIOURAL_HEALTH_SPECIALIST) {
            if (!engagementDraft.ConsentFormId) assessmentDetails.push('Consent form is not completed');
            if (memberAssessmentHistory.ShowCarePlanAlert === true) {
                assessmentDetails.push('Plans are expired');
            } else if (engagementDraft.GoalStatus === assessmentStatusEnum.IN_PROGRESS) {
                assessmentDetails.push('Set goal is not completed');
            }
            if (memberAssessmentHistory.ShowSdohAlert === true || memberAssessmentHistory.SdohStatus === assessmentStatusEnum.IN_PROGRESS) {
                assessmentDetails.push('SDOH is not completed');
            }
            if (engagementDraft.RequestStatus === assessmentStatusEnum.IN_PROGRESS) {
                assessmentDetails.push('Request is not completed');
            }
            if (memberAssessmentHistory.MiniMentalExamStatus === assessmentStatusEnum.IN_PROGRESS) {
                assessmentDetails.push('Mini mental exam is not completed');
            }
            if (memberAssessmentHistory.SubjectiveAssessmentStatus === assessmentStatusEnum.IN_PROGRESS) {
                assessmentDetails.push('Subjective assessment is not completed');
            }
            if (engagementDraft.InterventionStatus === assessmentStatusEnum.IN_PROGRESS) {
                assessmentDetails.push('Intervention is not completed');
            }
            if (memberAssessmentHistory.FactorAndRiskAssessmentStatus === assessmentStatusEnum.IN_PROGRESS) {
                assessmentDetails.push('Factor and risk assessment is not completed');
            }
            if (memberAssessmentHistory.ShowPhqAlert === true || memberAssessmentHistory.PhqStatus === assessmentStatusEnum.IN_PROGRESS) {
                assessmentDetails.push('PHQ9 is not completed');
            }
            if (memberAssessmentHistory.ShowCarePlanAlert === true || engagementDraft.CarePlanStatus === assessmentStatusEnum.IN_PROGRESS) {
                assessmentDetails.push('Integrated care plan are not completed');
            }
            if (engagementDraft.FollowUpStatus === assessmentStatusEnum.IN_PROGRESS) {
                assessmentDetails.push('Follow up assessment is not completed');
            }
            if (
                engagementDraft.MedicationReconciliationStatus === assessmentStatusEnum.IN_PROGRESS ||
                (isDisCharged && engagementDraft.MedicationReconciliationStatus !== assessmentStatusEnum.COMPLETED)
            ) {
                assessmentDetails.push('Medication reconciliation is not completed');
            }
            if (engagementDraft.ProgressNoteStatus !== assessmentStatusEnum.COMPLETED) {
                assessmentDetails.push('Progress notes are not completed');
            }
            if (!engagementDraft.FollowupRecommendations) {
                assessmentDetails.push('Follow up recommendations are not completed');
            }
            if (noteCounts === 0) {
                assessmentDetails.push('Summary notes are not selected');
            }
        } else if (selectedRole === roleEnum.FIELD_ONBOARDING) {
            if (!engagementDraft.ConsentFormId) assessmentDetails.push('Consent form is not completed');
            if (engagementDraft.DemographicStatus === assessmentStatusEnum.IN_PROGRESS) {
                assessmentDetails.push('Demographic info is not completed');
            }
            if (engagementDraft.HealthPlanStatus === assessmentStatusEnum.IN_PROGRESS) {
                assessmentDetails.push('Health plan is not completed');
            }
            if (memberAssessmentHistory.ShowSdohAlert === true || memberAssessmentHistory.SdohStatus === assessmentStatusEnum.IN_PROGRESS) {
                assessmentDetails.push('SDOH is not completed');
            }
            if (memberAssessmentHistory.PastPresentHistoryStatus === assessmentStatusEnum.IN_PROGRESS) {
                assessmentDetails.push('Past present history is not completed');
            }
            if (memberAssessmentHistory.PastSurgicalHistoryStatus === assessmentStatusEnum.IN_PROGRESS) {
                assessmentDetails.push('Past surgical history is not completed');
            }
            if (engagementDraft.MedicationReconciliationStatus === assessmentStatusEnum.IN_PROGRESS) {
                assessmentDetails.push('Medication reconciliation is not completed');
            }
            if (memberAssessmentHistory.ShowPhqAlert === true || memberAssessmentHistory.PhqStatus === assessmentStatusEnum.IN_PROGRESS) {
                assessmentDetails.push('PHQ9 is not completed');
            }
            if (
                engagementDraft.AllergyStatus === assessmentStatusEnum.IN_PROGRESS ||
                (memberAllergies.length === 0 && memberBasicInfo.NoKnownAllergies === false)
            ) {
                assessmentDetails.push('Allergy is not completed');
            }
            if (engagementDraft.PcpSpecialistStatus === assessmentStatusEnum.IN_PROGRESS) {
                assessmentDetails.push('Pcp and specialist is not completed');
            }
            if (engagementDraft.CareCircleStatus === assessmentStatusEnum.IN_PROGRESS) {
                assessmentDetails.push('Care circle is not completed');
            }
            if (engagementDraft.RequestStatus === assessmentStatusEnum.IN_PROGRESS) {
                assessmentDetails.push('Request is not completed');
            }
            if (engagementDraft.TabletStatus === assessmentStatusEnum.IN_PROGRESS) {
                assessmentDetails.push('Tablet is not completed');
            }
            if (engagementDraft.ProgressNoteStatus !== assessmentStatusEnum.COMPLETED) {
                assessmentDetails.push('Progress notes are not completed');
            }
            if (!engagementDraft.FollowupRecommendations) {
                assessmentDetails.push('Follow up recommendations are not completed');
            }
            if (noteCounts === 0) {
                assessmentDetails.push('Summary notes are not selected');
            }
        } else if (selectedRole === roleEnum.HRA) {
            if (!engagementDraft.ConsentFormId) {
                assessmentDetails.push('Consent form is not completed');
            }
            if (memberAssessmentHistory.VitalSignStatus !== assessmentStatusEnum.COMPLETED) {
                assessmentDetails.push('Vital Signs is not completed');
            }
            if (
                selectedRole === roleEnum.NURSE_PRACTITIONER &&
                (engagementDraft.AllergyStatus === assessmentStatusEnum.IN_PROGRESS ||
                    (memberAllergies.length === 0 && memberBasicInfo.NoKnownAllergies === false))
            ) {
                assessmentDetails.push('Allergy is not completed');
            }
            if (
                engagementDraft.MedicationReconciliationStatus === assessmentStatusEnum.IN_PROGRESS ||
                (isDisCharged && engagementDraft.MedicationReconciliationStatus !== assessmentStatusEnum.COMPLETED)
            ) {
                assessmentDetails.push('Medication reconciliation is not completed');
            }
            if (memberAssessmentHistory.PastPresentHistoryStatus === assessmentStatusEnum.IN_PROGRESS) {
                assessmentDetails.push('Past present history is not completed');
            }
            if (memberAssessmentHistory.PastSurgicalHistoryStatus === assessmentStatusEnum.IN_PROGRESS) {
                assessmentDetails.push('Past surgical history is not completed');
            }
            if (memberAssessmentHistory.ReviewSystemStatus === assessmentStatusEnum.IN_PROGRESS) {
                assessmentDetails.push('Review system is not completed');
            }
            if (memberAssessmentHistory.PhysicalExamStatus === assessmentStatusEnum.IN_PROGRESS) {
                assessmentDetails.push('Physical exam is not completed');
            }
            if (
                memberAssessmentHistory.HgbA1CStatus === assessmentStatusEnum.IN_PROGRESS ||
                (memberAssessmentHistory.IsHgbA1CNeedsReview && authData.Role === roleEnum.NURSE_PRACTITIONER)
            ) {
                assessmentDetails.push('HgbA1C assessment is not completed');
            }

            if (memberAssessmentHistory.ShowSdohAlert === true || memberAssessmentHistory.SdohStatus === assessmentStatusEnum.IN_PROGRESS) {
                assessmentDetails.push('SDOH is not completed');
            }
            if (memberAssessmentHistory.ShowPhqAlert === true || memberAssessmentHistory.PhqStatus === assessmentStatusEnum.IN_PROGRESS) {
                assessmentDetails.push('PHQ9 is not completed');
            }

            if (!Boolean(engagementDraft.TobaccoAndDrugAssessmentId)) {
                if (tobaccoAndDrug?.Score >= 3 || tobaccoAndDrug?.AuditAssessment?.Score >= 8) {
                    assessmentDetails.push(`${assessmentMenuEnum.TOBACCO_AND_DRUG} assessment needs to be reviewed within 30 days.`);
                } else if (tobaccoAndDrug?.Score <= 3 || tobaccoAndDrug?.AuditAssessment?.Score <= 8) {
                    assessmentDetails.push(`The provider should be alerted to do the ${assessmentMenuEnum.TOBACCO_AND_DRUG} assessment again in 90 days`);
                }
            }

            if (noteCounts === 0) {
                assessmentDetails.push('Summary notes are not selected');
            }
        } else if (selectedRole === roleEnum.TRANSITIONAL_CARE_COORDINATOR) {
            if (!engagementDraft.ConsentFormId) assessmentDetails.push('Consent form is not completed');
            if (memberAssessmentHistory.VitalSignStatus !== assessmentStatusEnum.COMPLETED) {
                assessmentDetails.push('Vital Signs is not completed');
            }
            if (memberAssessmentHistory.ShowSdohAlert === true || memberAssessmentHistory.SdohStatus === assessmentStatusEnum.IN_PROGRESS) {
                assessmentDetails.push('SDOH is not completed');
            }
            if (
                engagementDraft.MedicationReconciliationStatus === assessmentStatusEnum.IN_PROGRESS ||
                (isDisCharged && engagementDraft.MedicationReconciliationStatus !== assessmentStatusEnum.COMPLETED)
            ) {
                assessmentDetails.push('Medication reconciliation is not completed');
            }
            if (engagementDraft.ProgressNoteStatus !== assessmentStatusEnum.COMPLETED) {
                assessmentDetails.push('Progress notes are not completed');
            }
            if (!engagementDraft.FollowupRecommendations) {
                assessmentDetails.push('Follow up recommendations are not completed');
            }
            if (noteCounts === 0) {
                assessmentDetails.push('Summary notes are not selected');
            }
        }

        setAssessmentWarnings(assessmentDetails);
    };

    const handleOnSelectDxFromClaims = (val: any, index: number, dxIndex: number) => {
        dispatch(prepareICD10Hierarchy(val, index, dxIndex));
    };

    const renderDiagnosisList = (title: string, diagnosises: ICD10[], selectedDiagnosis: string[]) => {
        if (!Boolean(diagnosises?.length)) {
            return null;
        }

        return (
            <Stack spacing={2} marginBottom={2}>
                <Typography variant="subtitle2" component="label">
                    {title}
                </Typography>
                <Box>
                    {diagnosises.map((diagnosis, index) => (
                        <Chip
                            key={`diagnosis-${diagnosis.Id}-${index}`}
                            sx={{ mb: 2, mr: 2 }}
                            disabled={selectedDiagnosis.some((s) => s === `${diagnosis.Code.replaceAll('.', '')}`)}
                            onClick={() => {
                                handleOnSelectDxFromClaims(diagnosis.Code, diagnosisFromClaims.Index, diagnosisFromClaims.DxIndex);
                                setDiagnosisFromClaims(new DiagnosisFromClaimsState());
                            }}
                            label={`${diagnosis.Code} ${diagnosis.Name}`}
                            color="primary"
                            variant="outlined"
                        />
                    ))}
                </Box>
            </Stack>
        );
    };

    const renderDiagnosisFromClaimsModal = () => {
        const selectedDiagnosis = [];
        encounterList.Encounters[diagnosisFromClaims.Index]?.DiagnosisList.forEach((d) => selectedDiagnosis.push(d.Diagnosis?.substring(0, 3)));

        encounterList.Encounters[diagnosisFromClaims.Index]?.DiagnosisList.forEach((d) => selectedDiagnosis.push(d.DiagnosisPointer?.Code.replaceAll('.', '')));

        encounterList.Encounters[diagnosisFromClaims.Index]?.DiagnosisList.forEach((d) => selectedDiagnosis.push(d.FirstDiagnosis?.Code.replaceAll('.', '')));

        encounterList.Encounters[diagnosisFromClaims.Index]?.DiagnosisList.forEach((d) => selectedDiagnosis.push(d.SecondDiagnosis?.Code.replaceAll('.', '')));

        encounterList.Encounters[diagnosisFromClaims.Index]?.DiagnosisList.forEach((d) => selectedDiagnosis.push(d.ThirdDiagnosis?.Code.replaceAll('.', '')));

        encounterList.Encounters[diagnosisFromClaims.Index]?.DiagnosisList.forEach((d) => selectedDiagnosis.push(d.FourthDiagnosis?.Code.replaceAll('.', '')));

        return (
            <Dialog
                open={diagnosisFromClaims.Show}
                maxWidth={false}
                PaperProps={{
                    sx: { alignSelf: 'flex-start' },
                }}
            >
                <DialogTitle>Select Diagnosis</DialogTitle>
                <DialogContent dividers sx={{ width: '800px', maxWidth: '100%' }}>
                    {isFetching || isDiagnosisFetching ? (
                        <Box display="flex" justifyContent="center" className="loader-container">
                            <CircularProgress />
                        </Box>
                    ) : (
                        <React.Fragment>
                            {renderDiagnosisList('Clinical Data', diagnosisesByFilter, selectedDiagnosis)}
                            {renderDiagnosisList('Past Encounters', memberPastDiagnosysByFilter, selectedDiagnosis)}
                            {renderDiagnosisList('Claims', claimsDiagnosisByFilter, selectedDiagnosis)}
                        </React.Fragment>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button size="small" type="button" onClick={() => setDiagnosisFromClaims(new DiagnosisFromClaimsState())} variant="outlined">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    const handleOnCloseDefaultDiagnosis = () => {
        setShowDefaultDiagnosisModal(false);
        dispatch(getDefaultDiagnosis(memberId));
    };

    const handleSubmitDefaultDiagnosis = (values: DiagnosisDraft) => {
        dispatch(
            updateDefaultDiagnosis({ ...memberDefaultDiagnosis, DefaultDiagnosis: values, MemberId: memberId }, () => {
                setShowDefaultDiagnosisModal(false);
            })
        );
    };

    const renderDefaultPrimaryDiagnosis = () => {
        const inputName = 'default-primary-auto-complete';

        return (
            <Dialog sx={classes.form} open={showDefaultDiagnosisModal} onClose={handleOnCloseDefaultDiagnosis} scroll="paper">
                <Formik
                    initialValues={memberDefaultDiagnosis.DefaultDiagnosis}
                    onSubmit={handleSubmitDefaultDiagnosis}
                    validationSchema={defaultDiagnosisSchema}
                    enableReinitialize={true}
                >
                    {({ values, setFieldValue, errors, handleChange, handleBlur, submitCount }: FormikProps<DiagnosisDraft>) => (
                        <Form>
                            <DialogTitle id="scroll-dialog-drafts" display="flex" alignItems="center" justifyContent="space-between">
                                <span>Set Default Primary Diagnosis</span>
                            </DialogTitle>
                            <DialogContent dividers={true} style={{ width: '500px' }}>
                                <Autocomplete
                                    id={`primary-auto-select-default`}
                                    fullWidth
                                    isOptionEqualToValue={(option, value) => option.Name === value.Name}
                                    getOptionLabel={(option: any) => (typeof option === 'object' ? `${option.Code} ${option.Name}` : option || '')}
                                    value={values.Diagnosis as any}
                                    options={diagnosys || []}
                                    loading={isFetchingDisgnosys && focusInputName === inputName}
                                    freeSolo
                                    onChange={(e, value: any) => handleOnChangeDefaultDiagnosis(value, 'DiagnosisFirstList')}
                                    PopperComponent={PopperMy}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Diagnosis"
                                            required
                                            variant="outlined"
                                            onChange={(e) => {
                                                dispatch(getICD10DiagnosisByKeyword(e.target.value, engagementDraft?.AssessedRole?.Id));
                                            }}
                                            onFocus={() => setFocusInputName(inputName)}
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <React.Fragment>
                                                        {isFetchingDisgnosys && focusInputName === inputName ? (
                                                            <CircularProgress color="inherit" size={20} />
                                                        ) : null}
                                                        {params.InputProps.endAdornment}
                                                    </React.Fragment>
                                                ),
                                            }}
                                            error={Boolean(errors.Diagnosis) && Boolean(submitCount)}
                                        />
                                    )}
                                />
                                {Boolean(values.DiagnosisFirstList?.length) ? (
                                    <FormControl fullWidth error={Boolean(errors.FirstDiagnosis || errors.FirstDiagnosis?.Code) && Boolean(submitCount)}>
                                        <Select
                                            fullWidth
                                            id={`default-primary-first-type-select`}
                                            value={Boolean(values.FirstDiagnosis?.Code) ? values.FirstDiagnosis.Code : 0}
                                            className="hide-textinput-label"
                                            placeholder="Select Diagnosis"
                                            onChange={(e) => {
                                                const selectedDiagnosis = values.DiagnosisFirstList.find((d) => d.Code === e.target.value);
                                                handleOnChangeDifalultDx(selectedDiagnosis, 'FirstDiagnosis', 'DiagnosisSecondList');
                                            }}
                                            error={Boolean(errors.FirstDiagnosis || errors.FirstDiagnosis?.Code) && Boolean(submitCount)}
                                        >
                                            <MenuItem value={0}>Select Diagnosis</MenuItem>
                                            {values.DiagnosisFirstList.map((category) => (
                                                <MenuItem
                                                    key={`menu_item_${category.Code}`}
                                                    value={category.Code}
                                                >{`${category.Code} ${category.Name}`}</MenuItem>
                                            ))}
                                        </Select>
                                        {Boolean(errors.FirstDiagnosis || errors.FirstDiagnosis?.Code) && Boolean(submitCount) ? (
                                            <FormHelperText error>Diagnosis is required.</FormHelperText>
                                        ) : null}
                                    </FormControl>
                                ) : null}
                                {Boolean(values.DiagnosisSecondList?.length) ? (
                                    <FormControl fullWidth error={Boolean(errors.SecondDiagnosis || errors.SecondDiagnosis?.Code) && Boolean(submitCount)}>
                                        <Select
                                            fullWidth
                                            id={`default-primary-second-select`}
                                            value={Boolean(values.SecondDiagnosis?.Code) ? values.SecondDiagnosis.Code : 0}
                                            className="hide-textinput-label"
                                            placeholder="Select Diagnosis"
                                            onChange={(e) => {
                                                const selectedDiagnosis = values.DiagnosisSecondList.find((d) => d.Code === e.target.value);
                                                handleOnChangeDifalultDx(selectedDiagnosis, 'SecondDiagnosis', 'DiagnosisThirdList');
                                            }}
                                            error={Boolean(errors.SecondDiagnosis || errors.SecondDiagnosis?.Code) && Boolean(submitCount)}
                                        >
                                            <MenuItem value={0}>Select Diagnosis</MenuItem>
                                            {values.DiagnosisSecondList.map((category) => (
                                                <MenuItem
                                                    key={`menu_item_${category.Code}`}
                                                    value={category.Code}
                                                >{`${category.Code} ${category.Name}`}</MenuItem>
                                            ))}
                                        </Select>
                                        {Boolean(errors.SecondDiagnosis || errors.SecondDiagnosis?.Code) && Boolean(submitCount) ? (
                                            <FormHelperText error>Diagnosis is required.</FormHelperText>
                                        ) : null}
                                    </FormControl>
                                ) : null}
                                {Boolean(values.DiagnosisThirdList?.length) ? (
                                    <FormControl fullWidth error={Boolean(errors.ThirdDiagnosis || errors.ThirdDiagnosis?.Code) && Boolean(submitCount)}>
                                        <Select
                                            fullWidth
                                            id={`default-primary-third-select`}
                                            value={Boolean(values.ThirdDiagnosis?.Code) ? values.ThirdDiagnosis.Code : 0}
                                            className="hide-textinput-label"
                                            placeholder="Select Diagnosis"
                                            onChange={(e) => {
                                                const selectedDiagnosis = values.DiagnosisThirdList.find((d) => d.Code === e.target.value);
                                                handleOnChangeDifalultDx(selectedDiagnosis, 'ThirdDiagnosis', 'DiagnosisFourthList');
                                            }}
                                            error={Boolean(errors.ThirdDiagnosis || errors.ThirdDiagnosis?.Code) && Boolean(submitCount)}
                                        >
                                            <MenuItem value={0}>Select Diagnosis</MenuItem>
                                            {values.DiagnosisThirdList.map((category) => (
                                                <MenuItem
                                                    key={`menu_item_${category.Code}`}
                                                    value={category.Code}
                                                >{`${category.Code} ${category.Name}`}</MenuItem>
                                            ))}
                                        </Select>
                                        {Boolean(errors.ThirdDiagnosis || errors.ThirdDiagnosis?.Code) && Boolean(submitCount) ? (
                                            <FormHelperText error>Diagnosis is required.</FormHelperText>
                                        ) : null}
                                    </FormControl>
                                ) : null}
                                {Boolean(values.DiagnosisFourthList?.length) ? (
                                    <FormControl fullWidth error={Boolean(errors.FourthDiagnosis || errors.FourthDiagnosis?.Code) && Boolean(submitCount)}>
                                        <Select
                                            fullWidth
                                            id={`default-primary-fourth-select`}
                                            value={Boolean(values.FourthDiagnosis?.Code) ? values.FourthDiagnosis.Code : 0}
                                            className="hide-textinput-label"
                                            placeholder="Select Diagnosis"
                                            onChange={(e) =>
                                                handleOnChangeDifalultDx(
                                                    values.DiagnosisFourthList.find((d) => d.Code === e.target.value),
                                                    'FourthDiagnosis'
                                                )
                                            }
                                            error={Boolean(errors.FourthDiagnosis || errors.FourthDiagnosis?.Code) && Boolean(submitCount)}
                                        >
                                            <MenuItem value={0}>Select Diagnosis</MenuItem>
                                            {values.DiagnosisFourthList.map((category) => (
                                                <MenuItem
                                                    key={`menu_item_${category.Code}`}
                                                    value={category.Code}
                                                >{`${category.Code} ${category.Name}`}</MenuItem>
                                            ))}
                                        </Select>
                                        {Boolean(errors.FourthDiagnosis || errors.FourthDiagnosis?.Code) && Boolean(submitCount) ? (
                                            <FormHelperText error>Diagnosis is required.</FormHelperText>
                                        ) : null}
                                    </FormControl>
                                ) : null}
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    className="text-transform-none"
                                    disabled={!Boolean(values.Diagnosis) || isSavingDefaultDiagnosis}
                                    endIcon={isSavingDefaultDiagnosis ? <CircularProgress size={18} color="inherit" /> : null}
                                >
                                    Save
                                </Button>
                                <Button variant="outlined" onClick={handleOnCloseDefaultDiagnosis} className="text-transform-none">
                                    Cancel
                                </Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </Dialog>
        );
    };

    const renderWarnings = () => {
        return (
            <Collapse in={showWarnings} timeout="auto" unmountOnExit>
                <List sx={classes.warningList}>
                    {assessmentWarnings.map((warning, index) => (
                        <ListItem key={`warning-item-${index}`}>{warning}</ListItem>
                    ))}
                </List>
            </Collapse>
        );
    };

    return (
        <Grid container direction="column" spacing={2} className="content-panel">
            <Grid item className="content-form">
                {isLoading || isFetching ? (
                    <Box display="flex" justifyContent="center" className="loader-container">
                        <CircularProgress />
                    </Box>
                ) : (
                    <>
                        <Stack direction="row" justifyContent="space-between" spacing={2} marginBottom={4}>
                            {disableWarnings ? (
                                <div />
                            ) : (
                                <Button variant="text" onClick={() => setShowWarnings(!showWarnings)}>
                                    {showWarnings ? 'Hide' : 'Show'} Warnings
                                </Button>
                            )}
                            <Button variant="outlined" onClick={() => setShowDefaultDiagnosisModal(true)}>
                                Set default primary diagnosis
                            </Button>
                        </Stack>
                        {renderWarnings()}
                        <FormControl style={{ marginBottom: '16px', width: '150px' }}>
                            <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Gender"
                                value={memberBasicInfo.Gender ? genders.find((x) => x.label === memberBasicInfo.Gender)?.value : ''}
                                onChange={(e: any) => {
                                    dispatch(setMemberBasicInfo({ ...memberBasicInfo, Gender: genders.find((x) => x.value === e.target.value).label }));
                                    dispatch(saveGender(memberBasicInfo.Id, e.target.value));
                                }}
                            >
                                {genders.map(function (option) {
                                    return (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                        <TableContainer component={Paper} sx={classes.procedureOffTable}>
                            <Table aria-label="simple table" style={{ tableLayout: 'fixed' }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell width="35%">CPT Category</TableCell>
                                        <TableCell width="35%">Primary CPT</TableCell>
                                        <TableCell width="20%"></TableCell>
                                        <TableCell width="10%"></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {encounterList.Encounters?.map((encounter, index) => (
                                        <React.Fragment key={`encounter_list_${index}_${encounter.Id || encounter.tempId}`}>
                                            <TableRow>
                                                <TableCell component="th" scope="row">
                                                    <Select
                                                        fullWidth
                                                        id={`admission-type-select-${index}`}
                                                        value={encounter.CategoryGroupId}
                                                        size="small"
                                                        className="hide-textinput-label"
                                                        onChange={(e) => handleOnChangeCpt(e.target.value, 'CategoryGroupId', index)}
                                                    >
                                                        {cptCodeCategories.map((category) => (
                                                            <MenuItem key={`menu_item_${category.Id}`} value={category.Id}>
                                                                {category.GroupName}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <Autocomplete
                                                        id={`cpt-codes-auto-complete-${index}`}
                                                        isOptionEqualToValue={(option, value) => option.Id === value.Id}
                                                        getOptionLabel={(option) => (option as any).Name}
                                                        inputValue={
                                                            prepareCPTCodes(encounter.CategoryGroupId).find((c) => c.Id === encounter.CptCodeId)?.Name || ''
                                                        }
                                                        value={
                                                            (prepareCPTCodes(encounter.CategoryGroupId).find((c) => c.Id === encounter.CptCodeId) ||
                                                                null) as any
                                                        }
                                                        options={filteredCPTCodes(encounter.CategoryGroupId)}
                                                        getOptionDisabled={(option) =>
                                                            encounterList.Encounters.some((e) => e.CptCodeId === option.Id && e.CptCodeId !== encounter.Id)
                                                        }
                                                        size="small"
                                                        freeSolo
                                                        onChange={(e, value) =>
                                                            handleOnChangeCptCode((value as any)?.Id, 'CptCodeId', index, encounter.CategoryGroupId)
                                                        }
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                className="hide-textinput-label"
                                                                variant="outlined"
                                                                error={showCptErrorMsg && !Boolean(encounter.CptCode)}
                                                                InputProps={{
                                                                    ...params.InputProps,
                                                                    endAdornment: <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>,
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {encounter.CptCodeId &&
                                                    prepareCPTCodes(encounter.CategoryGroupId).find((c) => c.Id === encounter.CptCodeId) ? (
                                                        <Select
                                                            disabled={
                                                                !prepareCPTCodes(encounter.CategoryGroupId).find((c) => c.Id === encounter.CptCodeId)
                                                                    ?.HasMultiUnit
                                                            }
                                                            fullWidth
                                                            id={`units-type-select-${index}`}
                                                            value={encounter.Units}
                                                            size="small"
                                                            className="hide-textinput-label"
                                                            defaultValue={1}
                                                            onChange={(e) => handleOnChangeCpt(e.target.value, 'Units', index)}
                                                        >
                                                            <MenuItem value={0} disabled>
                                                                Select Units
                                                            </MenuItem>
                                                            {prepareCPTUnits().map((unit, index) => (
                                                                <MenuItem key={`menu_item_${unit.Id}`} value={unit.Units}>
                                                                    {unit.Units * unit.UnitValue} {unit.UnitLabel}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    ) : null}
                                                </TableCell>
                                                <TableCell component="th" align="right">
                                                    <IconButton aria-label="delete" style={{ color: 'red' }} onClick={() => handleOnClickRemove(index)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan={4} className={encounterList.Encounters.length !== index + 1 ? 'bottom-border' : ''}>
                                                    <Box sx={{ pt: 0 }}>
                                                        <Stack direction="row" alignItems="center">
                                                            <Typography variant="h6" component="label">
                                                                Diagnosis
                                                            </Typography>
                                                            <IconButton
                                                                disabled={encounter.DiagnosisList?.length > 3}
                                                                aria-label="delete"
                                                                color="primary"
                                                                onClick={() => handleOnClickAddDiagnosis(index)}
                                                            >
                                                                <AddIcon />
                                                            </IconButton>
                                                        </Stack>
                                                        <Table size="small" style={{ width: '100%', tableLayout: 'fixed' }}>
                                                            <TableBody>
                                                                {(encounter.DiagnosisList || []).map((dx, dxIndex) => {
                                                                    const canShowDefault =
                                                                        dxIndex === 0 && Boolean(memberDefaultDiagnosis?.DefaultDiagnosis?.Diagnosis);
                                                                    const paddingClassName = canShowDefault ? 'pb-50' : '';
                                                                    const defaultDiagnosis = paddingClassName
                                                                        ? memberDefaultDiagnosis?.DefaultDiagnosis?.Diagnosis.split(' ')[0]
                                                                        : '';

                                                                    return (
                                                                        <TableRow
                                                                            key={`diagnosis-row-${index}-${dxIndex}`}
                                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                        >
                                                                            <TableCell width="2.5%" className={paddingClassName}>
                                                                                {dxIndex + 1}
                                                                            </TableCell>
                                                                            <TableCell sx={{ pl: 0 }}>
                                                                                <Autocomplete
                                                                                    id={`primary-auto-select-${index}-${dxIndex}`}
                                                                                    fullWidth
                                                                                    isOptionEqualToValue={(option, value) => option.Name === value.Name}
                                                                                    getOptionLabel={(option: any) =>
                                                                                        typeof option === 'object'
                                                                                            ? `${option.Code} ${option.Name}`
                                                                                            : option || ''
                                                                                    }
                                                                                    value={dx.Diagnosis as any}
                                                                                    options={prepareDiagnosis(index)}
                                                                                    loading={
                                                                                        isFetchingDisgnosys &&
                                                                                        focusInputName === `primary-auto-complete-${index}-${dxIndex}`
                                                                                    }
                                                                                    freeSolo
                                                                                    size="small"
                                                                                    renderOption={(props, option) =>
                                                                                        option.Id === diagnosisFromClaimsId ? (
                                                                                            <ListItem
                                                                                                {...props}
                                                                                                onClick={null}
                                                                                                sx={classes.selectInputLinkItem}
                                                                                            >
                                                                                                <Link
                                                                                                    onClick={() =>
                                                                                                        setDiagnosisFromClaims({
                                                                                                            Show: true,
                                                                                                            ParentPropName: 'PrimaryDiagnosis',
                                                                                                            PropName: 'Diagnosis',
                                                                                                            Index: index,
                                                                                                            DxIndex: dxIndex,
                                                                                                        })
                                                                                                    }
                                                                                                >
                                                                                                    {option.Code}
                                                                                                </Link>
                                                                                            </ListItem>
                                                                                        ) : (
                                                                                            <ListItem {...props}>{`${option.Code} ${option.Name}`}</ListItem>
                                                                                        )
                                                                                    }
                                                                                    onChange={(e, value: any) => {
                                                                                        handleOnChangePrimaryDx(value, 'Diagnosis', index, dxIndex);
                                                                                        handleOnChangeSelect(value, index, dxIndex, 'DiagnosisFirstList');
                                                                                    }}
                                                                                    PopperComponent={PopperMy}
                                                                                    renderInput={(params) => (
                                                                                        <TextField
                                                                                            {...params}
                                                                                            className="hide-textinput-label"
                                                                                            variant="outlined"
                                                                                            onChange={(e) =>
                                                                                                dispatch(
                                                                                                    getICD10DiagnosisByKeyword(
                                                                                                        e.target.value,
                                                                                                        engagementDraft?.AssessedRole?.Id
                                                                                                    )
                                                                                                )
                                                                                            }
                                                                                            onFocus={() =>
                                                                                                setFocusInputName(`primary-auto-complete-${index}-${dxIndex}`)
                                                                                            }
                                                                                            InputProps={{
                                                                                                ...params.InputProps,
                                                                                                endAdornment: (
                                                                                                    <React.Fragment>
                                                                                                        {isFetchingDisgnosys &&
                                                                                                        focusInputName === `primary-auto-complete-${index}` ? (
                                                                                                            <CircularProgress color="inherit" size={20} />
                                                                                                        ) : null}
                                                                                                        {params.InputProps.endAdornment}
                                                                                                    </React.Fragment>
                                                                                                ),
                                                                                            }}
                                                                                        />
                                                                                    )}
                                                                                />
                                                                                {canShowDefault ? (
                                                                                    <Button
                                                                                        sx={{ fontSize: '12px' }}
                                                                                        size="small"
                                                                                        className="mt-10"
                                                                                        onClick={() => handleOnClickSetDefaultPrimary(index)}
                                                                                    >
                                                                                        Set {defaultDiagnosis} as primary diagnosis
                                                                                    </Button>
                                                                                ) : null}
                                                                            </TableCell>
                                                                            <TableCell className={paddingClassName}>
                                                                                {Boolean(dx.DiagnosisFirstList?.length) ? (
                                                                                    <Select
                                                                                        fullWidth
                                                                                        id={`primary-first-type-select-${index}`}
                                                                                        value={Boolean(dx.FirstDiagnosis?.Code) ? dx.FirstDiagnosis.Code : 0}
                                                                                        size="small"
                                                                                        className="hide-textinput-label"
                                                                                        placeholder="Select Diagnosis"
                                                                                        onChange={(e) => {
                                                                                            const selectedDiagnosis = dx.DiagnosisFirstList.find(
                                                                                                (d) => d.Code === e.target.value
                                                                                            );
                                                                                            handleOnChangeDx(
                                                                                                selectedDiagnosis,
                                                                                                'FirstDiagnosis',
                                                                                                index,
                                                                                                dxIndex
                                                                                            );
                                                                                            handleOnChangeSelect(
                                                                                                selectedDiagnosis,
                                                                                                index,
                                                                                                dxIndex,
                                                                                                'DiagnosisSecondList'
                                                                                            );
                                                                                        }}
                                                                                        error={!Boolean(dx.FirstDiagnosis?.Code) && showDxError}
                                                                                    >
                                                                                        <MenuItem value={0}>Select Diagnosis</MenuItem>
                                                                                        {dx.DiagnosisFirstList.map((category) => (
                                                                                            <MenuItem
                                                                                                key={`menu_item_${category.Code}`}
                                                                                                value={category.Code}
                                                                                            >{`${category.Code} ${category.Name}`}</MenuItem>
                                                                                        ))}
                                                                                    </Select>
                                                                                ) : null}
                                                                            </TableCell>
                                                                            <TableCell className={paddingClassName}>
                                                                                {Boolean(dx.DiagnosisSecondList?.length) ? (
                                                                                    <Select
                                                                                        fullWidth
                                                                                        id={`primary-second-select-${index}`}
                                                                                        value={Boolean(dx.SecondDiagnosis?.Code) ? dx.SecondDiagnosis.Code : 0}
                                                                                        size="small"
                                                                                        className="hide-textinput-label"
                                                                                        placeholder="Select Diagnosis"
                                                                                        onChange={(e) => {
                                                                                            const selectedDiagnosis = dx.DiagnosisSecondList.find(
                                                                                                (d) => d.Code === e.target.value
                                                                                            );
                                                                                            handleOnChangeDx(
                                                                                                selectedDiagnosis,
                                                                                                'SecondDiagnosis',
                                                                                                index,
                                                                                                dxIndex
                                                                                            );
                                                                                            handleOnChangeSelect(
                                                                                                selectedDiagnosis,
                                                                                                index,
                                                                                                dxIndex,
                                                                                                'DiagnosisThirdList'
                                                                                            );
                                                                                        }}
                                                                                        error={!Boolean(dx.SecondDiagnosis?.Code) && showDxError}
                                                                                    >
                                                                                        <MenuItem value={0}>Select Diagnosis</MenuItem>
                                                                                        {dx.DiagnosisSecondList.map((category) => (
                                                                                            <MenuItem
                                                                                                key={`menu_item_${category.Code}`}
                                                                                                value={category.Code}
                                                                                            >{`${category.Code} ${category.Name}`}</MenuItem>
                                                                                        ))}
                                                                                    </Select>
                                                                                ) : null}
                                                                            </TableCell>
                                                                            <TableCell className={paddingClassName}>
                                                                                {Boolean(dx.DiagnosisThirdList?.length) ? (
                                                                                    <Select
                                                                                        fullWidth
                                                                                        id={`primary-third-select-${index}`}
                                                                                        value={Boolean(dx.ThirdDiagnosis?.Code) ? dx.ThirdDiagnosis.Code : 0}
                                                                                        size="small"
                                                                                        className="hide-textinput-label"
                                                                                        placeholder="Select Diagnosis"
                                                                                        onChange={(e) => {
                                                                                            const selectedDiagnosis = dx.DiagnosisThirdList.find(
                                                                                                (d) => d.Code === e.target.value
                                                                                            );
                                                                                            handleOnChangeDx(
                                                                                                selectedDiagnosis,
                                                                                                'ThirdDiagnosis',
                                                                                                index,
                                                                                                dxIndex
                                                                                            );
                                                                                            handleOnChangeSelect(
                                                                                                selectedDiagnosis,
                                                                                                index,
                                                                                                dxIndex,
                                                                                                'DiagnosisFourthList'
                                                                                            );
                                                                                        }}
                                                                                        error={!Boolean(dx.ThirdDiagnosis?.Code) && showDxError}
                                                                                    >
                                                                                        <MenuItem value={0}>Select Diagnosis</MenuItem>
                                                                                        {dx.DiagnosisThirdList.map((category) => (
                                                                                            <MenuItem
                                                                                                key={`menu_item_${category.Code}`}
                                                                                                value={category.Code}
                                                                                            >{`${category.Code} ${category.Name}`}</MenuItem>
                                                                                        ))}
                                                                                    </Select>
                                                                                ) : null}
                                                                            </TableCell>
                                                                            <TableCell className={paddingClassName}>
                                                                                {Boolean(dx.DiagnosisFourthList?.length) ? (
                                                                                    <Select
                                                                                        fullWidth
                                                                                        id={`primary-fourth-select-${index}`}
                                                                                        value={Boolean(dx.FourthDiagnosis?.Code) ? dx.FourthDiagnosis.Code : 0}
                                                                                        size="small"
                                                                                        className="hide-textinput-label"
                                                                                        placeholder="Select Diagnosis"
                                                                                        onChange={(e) =>
                                                                                            handleOnChangeDx(
                                                                                                dx.DiagnosisFourthList.find((d) => d.Code === e.target.value),
                                                                                                'FourthDiagnosis',
                                                                                                index,
                                                                                                dxIndex
                                                                                            )
                                                                                        }
                                                                                        error={!Boolean(dx.FourthDiagnosis?.Code) && showDxError}
                                                                                    >
                                                                                        <MenuItem value={0}>Select Diagnosis</MenuItem>
                                                                                        {dx.DiagnosisFourthList.map((category) => (
                                                                                            <MenuItem
                                                                                                key={`menu_item_${category.Code}`}
                                                                                                value={category.Code}
                                                                                            >{`${category.Code} ${category.Name}`}</MenuItem>
                                                                                        ))}
                                                                                    </Select>
                                                                                ) : null}
                                                                            </TableCell>
                                                                            <TableCell width="5%" className={paddingClassName}>
                                                                                <IconButton
                                                                                    aria-label="delete"
                                                                                    style={{ color: 'red' }}
                                                                                    onClick={() => handleOnClickRemoveDiagnosis(index, dxIndex)}
                                                                                >
                                                                                    <DeleteIcon />
                                                                                </IconButton>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    );
                                                                })}
                                                            </TableBody>
                                                        </Table>
                                                    </Box>
                                                </TableCell>
                                            </TableRow>
                                        </React.Fragment>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Button
                            variant="outlined"
                            startIcon={<AddIcon />}
                            size="small"
                            onClick={handleOnClickAdd}
                            style={{ textTransform: 'none', marginTop: '15px' }}
                        >
                            Add More
                        </Button>
                    </>
                )}
                {renderDiagnosisFromClaimsModal()}
            </Grid>
            <Grid item justifyContent="flex-end" className="content-footer">
                <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    sx={classes.actionButton}
                    id="btn-encountered"
                    onClick={handleOnSkipEncounters}
                    disabled={isSaving}
                    endIcon={isSaving && clickedBtnId === 'btn-encountered' ? <CircularProgress size={18} color="inherit" /> : null}
                >
                    {isNotEncounterable ? 'Not to be Encountered (Team Assignment Request)' : 'Not to be Encountered'}
                </Button>
                {(isInHra && !isCoder && !isNursePractitioner) || isNotEncounterable ? null : (
                    <React.Fragment>
                        {isInHra && isCoder ? null : (
                            <Button
                                type="submit"
                                variant="contained"
                                color="success"
                                sx={classes.actionButton}
                                id="btn-review"
                                onClick={(e) => handleOnSave(e, true)}
                                disabled={(notAssessment && memberBasicInfo.Status === MemberStatusEnum.UndeterminedDescription) || isSaving}
                                endIcon={isSaving && clickedBtnId === 'btn-review' ? <CircularProgress size={18} color="inherit" /> : null}
                            >
                                Save for Review
                            </Button>
                        )}
                        {isInHra && isNursePractitioner ? null : (
                            <Button
                                type="submit"
                                variant="contained"
                                sx={classes.actionButton}
                                id="btn-claim"
                                onClick={(e) => handleOnSave(e)}
                                disabled={(notAssessment && memberBasicInfo.Status === MemberStatusEnum.UndeterminedDescription) || isSaving}
                                endIcon={isSaving && clickedBtnId === 'btn-claim' ? <CircularProgress size={18} color="inherit" /> : null}
                            >
                                Submit Claim
                            </Button>
                        )}
                    </React.Fragment>
                )}
            </Grid>
            {renderDefaultPrimaryDiagnosis()}
            <AlertDialog
                open={showDiagnosisAlert}
                onConfirm={() => setShowDiagnosisAlert(false)}
                okText="Ok"
                message={`Reminder! HgbA1C is required every six months with Hedis coding.`}
                hideCancelButton={true}
                bottomButtonCenter={true}
            />
        </Grid>
    );
};

export default ProcedureSignOff;
