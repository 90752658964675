import { useCallback, useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useNavigate } from 'react-router-dom';
import { Grid, IconButton, Paper, Tooltip, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import { usePopulationContext } from '../../../hooks/populationContext';
import { useHedisMetricsContext } from '../../../hooks/hedisMetricsContext';
import { configureStackedBarChartWithoutLegend } from '../../ChartConfiguration/ChartConfiguration';
import { getMedicationReconciliationSummary } from '../HedisMetricService';
import MedicationReconciliationSummary from '../../../Models/HedisMetrics/MedicationReconciliationSummary.model';
import { TimePeriodFilterEnum } from '../../../Enum/TimePeriodFilterEnum';
import { HedisMetricsTypeEnum } from '../../../Enum/HedisMetricsTypeEnum';
import FilterOptions from '../FilterOptions';
import '../../Widgets/Widgets.css';

const MedicationReconciliationSummaries = () => {
    const history = useNavigate();
    const { populations, isPopulationFetched } = usePopulationContext();
    const [isFetched, setIsFetched] = useState<boolean>(false);
    const [isGraphShow, setIsGraphShow] = useState<boolean>(false);
    const [isPrepared, setIsPrepared] = useState<boolean>(false);
    const [medicationReconciliationSummary, setMedicationReconciliationSummary] = useState<MedicationReconciliationSummary>();
    const [chartOptions, setChartOptions] = useState({});
    const [show, setShow] = useState(false);
    const {
        medicationReconciliationPopulation,
        setMedicationReconciliationPopulation,
        medicationReconciliationTeam,
        setMedicationReconciliationTeam,
        medicationReconciliationDateFilter,
        setMedicationReconciliationDateFilter,
        medicationReconciliationFromDate,
        setMedicationReconciliationFromDate,
        medicationReconciliationToDate,
        setMedicationReconciliationToDate,
        medicationReconciliationIncludePastOpenStatus,
        setMedicationReconciliationIncludePastOpenStatus,
        medicationReconciliationShowInActiveTeam,
        setMedicationReconciliationShowInActiveTeam,
        setHedisType,
        setPop,
        setTeam,
        setFilter,
        setFDate,
        setTDate,
        setIncludePastOpenStatus,
        setShowInActiveTeam,
    } = useHedisMetricsContext();

    const initialPage = useCallback(async () => {
        if (isFetched) {
            return;
        }

        if (!isGraphShow) {
            setChartOptions(
                configureStackedBarChartWithoutLegend(
                    'Medication Reconciliation Post Discharge',
                    'Documented vs Not Documented',
                    'Population',
                    'Count',
                    [{ name: '', data: [0] }],
                    [0]
                )
            );
        }

        setIsFetched(true);
    }, [isFetched, setIsFetched, isGraphShow]);

    useEffect(() => {
        initialPage();
    }, [initialPage]);

    useEffect(() => {
        if (
            isPopulationFetched &&
            (medicationReconciliationDateFilter !== TimePeriodFilterEnum.Custom ||
                (Boolean(medicationReconciliationFromDate) && Boolean(medicationReconciliationToDate)))
        ) {
            const changeFilter = async () => {
                let start = medicationReconciliationFromDate !== null ? new Date(medicationReconciliationFromDate).toDateString() : null;
                let end = medicationReconciliationToDate !== null ? new Date(medicationReconciliationToDate).toDateString() : null;
                const data = await getMedicationReconciliationSummary(
                    medicationReconciliationPopulation,
                    medicationReconciliationTeam,
                    medicationReconciliationDateFilter,
                    start,
                    end,
                    medicationReconciliationIncludePastOpenStatus,
                    medicationReconciliationShowInActiveTeam
                );
                setMedicationReconciliationSummary(data);
                setIsPrepared(true);
            };
            if (
                (medicationReconciliationFromDate !== null || medicationReconciliationToDate !== null) &&
                medicationReconciliationToDate < medicationReconciliationFromDate
            ) {
            } else {
                setChartOptions(
                    configureStackedBarChartWithoutLegend(
                        'Medication Reconciliation Post Discharge',
                        'Documented vs Not Documented',
                        'Population',
                        'Count',
                        [{ name: '', data: [], showInLegend: false, title: { text: null } }],
                        [0]
                    )
                );
                setIsGraphShow(false);
                changeFilter();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        medicationReconciliationPopulation,
        medicationReconciliationTeam,
        medicationReconciliationDateFilter,
        medicationReconciliationFromDate,
        medicationReconciliationToDate,
        isPopulationFetched,
        medicationReconciliationIncludePastOpenStatus,
        medicationReconciliationShowInActiveTeam,
    ]);

    useEffect(() => {
        if (medicationReconciliationSummary && isPrepared) {
            let xAxisValues = [];
            let yAxisValues = [];
            let yValues = [];
            let yVal;

            yValues = [];
            yVal = {
                y: medicationReconciliationSummary.Documented,
                name: 'Documented',
            };
            yValues.push(yVal);
            yAxisValues.push({ name: 'Documented', data: yValues, pointPadding: 0, color: '#9ec7e8', showInLegend: true });

            yValues = [];
            yVal = {
                y: medicationReconciliationSummary.NotDocumented,
                name: 'Not Documented',
            };
            yValues.push(yVal);
            yAxisValues.push({ name: 'Not Documented', data: yValues, pointPadding: 0, color: '#6495ED', showInLegend: true });

            if (medicationReconciliationPopulation === 0) {
                xAxisValues.push('All Populations');
            } else {
                let pop = populations.find((p) => p.value === medicationReconciliationPopulation);
                if (pop) {
                    xAxisValues.push(pop.name);
                }
            }

            setChartOptions(
                configureStackedBarChartWithoutLegend('Medication Reconciliation Post Discharge', '', '', 'Count', yAxisValues, xAxisValues, navigateToMembers)
            );

            setIsGraphShow(true);
            setIsPrepared(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [medicationReconciliationSummary, medicationReconciliationPopulation, populations, isPrepared]);

    const handleClick = (event) => {
        setShow(!show);
    };

    const navigateToMembers = () => {
        setPop(medicationReconciliationPopulation);
        setTeam(medicationReconciliationTeam);
        setFilter(medicationReconciliationDateFilter);
        setFDate(medicationReconciliationFromDate);
        setTDate(medicationReconciliationToDate);
        setIncludePastOpenStatus(medicationReconciliationIncludePastOpenStatus);
        setShowInActiveTeam(medicationReconciliationShowInActiveTeam);
        setHedisType(HedisMetricsTypeEnum.MedicationReconciliation);
        history(`/provider/metricsummary/hedismemberdetail/MedicationReconciliation`);
    };

    return (
        <Grid item xs={12} lg={6}>
            <Paper className="smartdashboard-widget" elevation={5}>
                <Grid container spacing={2} paddingLeft={2} paddingRight={2}>
                    <Grid item xs={12} textAlign="center">
                        <Typography display={'inline-block'} variant="h6" color={'primary'} onClick={navigateToMembers} className="pointer">
                            Medication Reconciliation Post Discharge
                        </Typography>
                        <Tooltip
                            open={show}
                            title={
                                <div>
                                    <Typography variant="body2">Within 30 days of discharge from a facility</Typography>

                                    <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                                        Documented :
                                    </Typography>

                                    <Typography variant="caption">Any of the CPT codes 1111F or 1159F</Typography>

                                    <Typography marginTop={1} fontWeight={800} variant="subtitle2">
                                        Not Documented :
                                    </Typography>
                                    <Typography variant="caption">No CPT codes recorded</Typography>
                                </div>
                            }
                            PopperProps={{ style: { zIndex: 1000 } }}
                        >
                            <IconButton onClick={handleClick}>
                                <InfoIcon sx={{ width: '18px', height: '18px' }} />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <FilterOptions
                        selectedTeam={medicationReconciliationTeam}
                        setSelectedTeam={setMedicationReconciliationTeam}
                        dateFilter={medicationReconciliationDateFilter}
                        setDateFilter={setMedicationReconciliationDateFilter}
                        fromDate={medicationReconciliationFromDate}
                        setFromDate={setMedicationReconciliationFromDate}
                        toDate={medicationReconciliationToDate}
                        setToDate={setMedicationReconciliationToDate}
                        population={medicationReconciliationPopulation}
                        setPopulation={setMedicationReconciliationPopulation}
                        includePastOpenStatusMembers={medicationReconciliationIncludePastOpenStatus}
                        setIncludePastOpenStatusMembers={setMedicationReconciliationIncludePastOpenStatus}
                        hedisType={HedisMetricsTypeEnum.MedicationReconciliation}
                        showInActiveTeams={medicationReconciliationShowInActiveTeam}
                        setShowInActiveTeams={setMedicationReconciliationShowInActiveTeam}
                    />
                    <Grid item xs={12} marginTop={1} sx={{ position: 'relative' }}>
                        {!isGraphShow ? (
                            <Box className="loader-center">
                                <CircularProgress />
                            </Box>
                        ) : null}
                        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    );
};

export default MedicationReconciliationSummaries;
