import { DataGrid, gridClasses, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { dateFormatter } from '../../../../../../utils/timeFormat';
import { isDefaultDate } from '../../../../../../utils/common';
import { RootState } from '../../../../../../reducers';
import { useDispatch, useSelector } from 'react-redux';
import { clearSelections, getMIHAAlerts, getTopThreeMatches, setRowSelectionModel, setSelectedAlert, setUnmatchedId } from '../../../../../../store/hie.slice';
import moment from 'moment';
import { useEffect } from 'react';
const MIHAAlerts = () => {
    const dispatch = useDispatch();

    const { mihaAlerts, unmatchedId, showArchivedAlerts } = useSelector((state: RootState) => state.hie);

    const columns: GridColDef[] = [
        {
            field: 'LastName',
            headerName: 'Last Name',
            minWidth: 160,
        },
        {
            field: 'FirstName',
            headerName: 'First Name',
            minWidth: 160,
        },
        {
            field: 'DOB',
            headerName: 'DOB',
            minWidth: 140,
        },
        {
            field: 'CHPDownloadDate',
            headerName: 'Download Date',
            minWidth: 140,
            valueGetter: (params: GridValueGetterParams) =>
                `${isDefaultDate(params.row.CHPDownloadDate) ? '-' : dateFormatter(new Date(params.row.CHPDownloadDate))}`,
        },
        {
            field: 'SendingFacility',
            headerName: 'Sending Facility',
            minWidth: 200,
        },
        {
            field: 'VisitType',
            headerName: 'Visit Type',
            minWidth: 200,
        },
        {
            field: 'PCP',
            headerName: 'PCP',
            minWidth: 200,
        },
        {
            field: 'Diagnosis',
            headerName: 'Diagnosis',
            minWidth: 600,
        },
        {
            field: 'AdmitDate',
            headerName: 'Admission Date',
            minWidth: 160,
            valueGetter: (params: GridValueGetterParams) => `${isDefaultDate(params.row.AdmitDate) ? '-' : moment.utc(params.row.AdmitDate).format('L')}`,
        },
        {
            field: 'AdmitTime',
            headerName: 'Admission Time',
            minWidth: 160,
            valueGetter: (params: GridValueGetterParams) => `${new Date(params.row.AdmitTime).toLocaleTimeString()}`,
        },
        {
            field: 'Location',
            headerName: 'Location',
            minWidth: 200,
        },
    ];

    useEffect(() => {
        dispatch(getMIHAAlerts());
    }, [dispatch]);

    return (
        <>
            <DataGrid
                onRowClick={(params: any) => {
                    const { DOB, Id, Patient } = params.row;
                    const names = Patient.split(' ');
                    dispatch(clearSelections());
                    dispatch(setUnmatchedId(Id));
                    dispatch(setSelectedAlert(params.row));
                    dispatch(getTopThreeMatches(names[0], names[names.length - 1], DOB));
                }}
                autoHeight
                rows={showArchivedAlerts ? mihaAlerts : mihaAlerts.filter((alert) => alert.Archived !== true)}
                columns={columns}
                checkboxSelection={unmatchedId.length > 0 ? true : false}
                onSelectionModelChange={(ids) => {
                    dispatch(setRowSelectionModel(ids));
                }}
                pageSize={10}
                getRowHeight={() => 'auto'}
                rowsPerPageOptions={[5]}
                getRowId={(row) => row.Id}
                getRowClassName={(params) => (params.row.Archived ? 'archived' : '')}
                sx={{
                    [`& .${gridClasses.cell}`]: {
                        py: 1,
                    },
                    [`& .archived`]: {
                        background: 'rgba(200,200,200,0.4)',
                        fontStyle: 'italic',
                    },
                }}
            />
        </>
    );
};

export default MIHAAlerts;
