export enum HIEAlertTypeEnum {
    ADT = 1,
    MIHA = 2,
    ADT_O = 3,
    COVID = 4,
    MC = 5,
    LAB = 6,
    FUHFUM = 7,
    HEDIS_LAB = 8,
}
