import { Box, Typography } from '@mui/material';
import { DataGrid, gridClasses, GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import { RootState } from '../../../../../../reducers';
import { useDispatch, useSelector } from 'react-redux';
import {
    clearSelections,
    getHedisLabAlerts,
    getTopThreeMatches,
    setPageNumber,
    setRowSelectionModel,
    setSelectedAlert,
    setUnmatchedId,
} from '../../../../../../store/hie.slice';
import { dateFormatter } from '../../../../../../utils/timeFormat';
import { isDefaultDate } from '../../../../../../utils/common';
import { useEffect } from 'react';

const HedisLabAlerts = () => {
    const dispatch = useDispatch();

    const { hedisLabAlerts, pageNumber, pageSize, showArchivedAlerts, totalAlerts, unmatchedId } = useSelector((state: RootState) => state.hie);

    const columns: GridColDef[] = [
        {
            field: 'LastName',
            headerName: 'Last Name',
            minWidth: 160,
        },
        {
            field: 'FirstName',
            headerName: 'First Name',
            minWidth: 160,
        },
        {
            field: 'DOB',
            headerName: 'DOB',
            width: 140,
        },
        {
            field: 'CHPDownloadDate',
            headerName: 'Download Date',
            minWidth: 160,
            valueGetter: (params: GridValueGetterParams) =>
                `${isDefaultDate(params.row.CHPDownloadDate) ? '-' : dateFormatter(new Date(params.row.CHPDownloadDate))}`,
        },
        {
            field: 'DischargeDate',
            headerName: 'Discharge Date',
            minWidth: 160,
            valueGetter: (params: GridValueGetterParams) =>
                `${isDefaultDate(params.row.DischargeDate) ? '-' : dateFormatter(new Date(params.row.DischargeDate))}`,
        },
        {
            field: 'TestOrdered',
            headerName: 'Test Ordered',
            minWidth: 200,
        },
        {
            field: 'RequestDate',
            headerName: 'Lab Requested',
            minWidth: 140,
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Box>
                        {params.row.ObservationLabRequestDate && <Typography variant="body2">{params.row.ObservationLabRequestDate} @</Typography>}
                        <Typography variant="body2">{params.row.ObservationLabRequestTime}</Typography>
                    </Box>
                );
            },
        },
        {
            field: 'ObservationLabRequestDate',
            headerName: 'Lab Completed',
            minWidth: 140,
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Box>
                        {params.row.ObservationLabDate && <Typography variant="body2">{params.row.ObservationLabDate} @</Typography>}
                        <Typography variant="body2">{params.row.ObservationLabTime}</Typography>
                    </Box>
                );
            },
        },
        {
            field: 'LabOrderResult',
            headerName: 'Lab Result',
            minWidth: 300,
            flex: 1,
            renderCell: (params: GridRenderCellParams) => {
                let results = params.row.LabOrderResult?.split('\n');
                return (
                    <Box>
                        {results?.map((el, index) => (
                            <Typography variant="body2" key={index}>
                                {el}
                            </Typography>
                        ))}
                    </Box>
                );
            },
        },
    ];

    useEffect(() => {
        dispatch(getHedisLabAlerts());
    }, [dispatch, pageNumber, pageSize]);

    return (
        <>
            <DataGrid
                onRowClick={(params: any) => {
                    const { DOB, Id, Patient } = params.row;
                    const names = Patient.split(' ');
                    dispatch(clearSelections());
                    dispatch(setUnmatchedId(Id));
                    dispatch(setSelectedAlert(params.row));
                    dispatch(getTopThreeMatches(names[0], names[names.length - 1], DOB));
                }}
                autoHeight
                rows={showArchivedAlerts ? hedisLabAlerts : hedisLabAlerts.filter((alert) => alert.Archived !== true)}
                columns={columns}
                checkboxSelection={unmatchedId.length > 0 ? true : false}
                onSelectionModelChange={(ids) => {
                    dispatch(setRowSelectionModel(ids));
                }}
                page={pageNumber}
                pageSize={pageSize}
                paginationMode="server"
                onPageChange={(newPage) => {
                    dispatch(setPageNumber(newPage));
                }}
                rowCount={totalAlerts}
                getRowHeight={() => 'auto'}
                rowsPerPageOptions={[5]}
                getRowId={(row) => row.Id}
                getRowClassName={(params) => (params.row.Archived ? 'archived' : '')}
                sx={{
                    [`& .${gridClasses.cell}`]: {
                        py: 1,
                    },
                    [`& .archived`]: {
                        background: 'rgba(200,200,200,0.4)',
                        fontStyle: 'italic',
                    },
                }}
            />
        </>
    );
};

export default HedisLabAlerts;
