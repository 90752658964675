import { Member } from '../../../Models/Member/Member.model';
import Appointment from '../../../Models/Scheduling/Appointment.model';
import CalendarModal from '../CalendarModal';
import MemberChartScheduler from '../MemberChartCalendar/MemberChartScheduler';

interface RightPanelProps {
    member?: Member;
    associatedEvent?: Appointment;
    openEditor: boolean;
    onCloseCalendarModal: () => void;
    dashboard: React.ReactNode;
    rightHandBoxContent: React.ReactNode;
}

const RightPanel: React.FC<RightPanelProps> = ({ member, associatedEvent, openEditor, onCloseCalendarModal, dashboard, rightHandBoxContent }) => (
    <>
        {member?.Id?.length > 0 && associatedEvent?.Id?.length > 0 ? (
            <>
                {dashboard}
                <CalendarModal
                    open={openEditor}
                    onClose={onCloseCalendarModal}
                    calendar={<MemberChartScheduler memberId={member.Id} preSelectedDate={associatedEvent.AppointmentDateTime} />}
                />
            </>
        ) : (
            rightHandBoxContent
        )}
    </>
);

export default RightPanel;
