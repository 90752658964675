import { Alert, Grid } from '@mui/material';
import { format } from 'date-fns';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RescheduledNonRecurrenceDto } from '../../../Models/Scheduling/RescheduledNonRecurrenceDto.model';
import { RescheduledRecurrenceDto } from '../../../Models/Scheduling/RescheduledRecurrenceDto.model';
import { RootState } from '../../../reducers';
import { setIsRescheduleModalOpen } from '../../../store/officeScheduler.slice';
import { setIsLoading, setShowMessage } from '../../../store/shared.slice';
import { OfficeSchedulerModeEnum } from '../../Calendar/Enums/OfficeSchedulerModeEnum';
import RescheduleEventModal from '../../Calendar/Modals/RescheduleEventModal';
import { mapEventData } from '../../Calendar/Services/CommonCalendarServices';
import { CalendarEventData } from '../../Calendar/types/CalendarEventData';
import { updateSchedulingCallItem } from '../OfficeSchedulingService';
import '../officeScheduling.css';
import MemberInfoPanel from './MemberInfoPanel';
import SchedulingButtonsPane from './SchedulingButtonsPane';
import SchedulingEngagementDisplay from './SchedulingEngagement/SchedulingEngagementDisplay';
import SchedulingEngagementInput from './SchedulingEngagement/SchedulingEngagementInput';
import { ConfirmBtn } from './schedulingDashboardButtons';
import MessageDisplay from '../../Shared/MessageDisplay';

interface SchedulingDashboardProps {
    mode: OfficeSchedulerModeEnum;
    getLatestListData: () => Promise<void>;
    setOpenEditor: any;
}

const SchedulingDashboard: React.FC<SchedulingDashboardProps> = ({ mode, getLatestListData, setOpenEditor }: SchedulingDashboardProps) => {
    const dispatch = useDispatch();
    const { associatedEvent, isRescheduleModalOpen, schedulingProvider, selectedCallItem } = useSelector((state: RootState) => state.officeSchedulerSlice);
    const [showAlert, setShowAlert] = useState(false);
    const exclusionDate = useMemo(() => {
        if (associatedEvent !== null) {
            return format(new Date(associatedEvent.AppointmentDateTime), 'yyyy-MM-dd');
        } else return '';
    }, [associatedEvent]);

    useEffect(() => {
        if (associatedEvent != null) {
            const docsEl = document.getElementById('docsEl');
            docsEl.scrollTop = 0;
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [associatedEvent]);

    const doSaveAndFinish = useCallback(async () => {
        const apptId = associatedEvent.Id;
        const response = await updateSchedulingCallItem(
            schedulingProvider.Id,
            apptId,
            selectedCallItem.CallStatus.split(' ').join(''),
            exclusionDate,
            format(new Date(selectedCallItem.LastContact), "yyyy-MM-dd'T'hh:mm:ss'.000Z'"),
            mode === OfficeSchedulerModeEnum.Rescheduling ? 'Rescheduling' : 'Confirmations',
            selectedCallItem.Note
        );
        if (response.status === 200) {
            await getLatestListData();
        }
    }, [
        associatedEvent.Id,
        schedulingProvider.Id,
        selectedCallItem.CallStatus,
        selectedCallItem.LastContact,
        selectedCallItem.Note,
        exclusionDate,
        mode,
        getLatestListData,
    ]);

    const reschedulableEvent = useMemo(() => {
        let newEvent = structuredClone(associatedEvent);
        newEvent.startDate = newEvent.AppointmentDateTime;
        newEvent.endDate = newEvent.AppointmentEndDateTime;
        const mappedData = mapEventData(newEvent) as CalendarEventData;
        mappedData.IsRescheduling = true;
        return mappedData;
    }, [associatedEvent]);

    useEffect(() => {
        if (!(selectedCallItem.Note?.length > 0) || !(selectedCallItem.CallStatus?.length > 0)) {
            setShowAlert(true);
        } else {
            setShowAlert(false);
        }
    }, [selectedCallItem.Note, selectedCallItem.CallStatus]);

    return (
        <Grid container spacing={2} direction="row">
            <MessageDisplay />
            <RescheduleEventModal
                eventData={reschedulableEvent}
                setLoadingOverlay={function (isLoading: boolean): void {
                    dispatch(setIsLoading(isLoading));
                }}
                open={isRescheduleModalOpen}
                onClose={() => {
                    dispatch(setIsRescheduleModalOpen(false));
                }}
                onRescheduleSuccess={(rescheduledAppointment: RescheduledNonRecurrenceDto) => {
                    dispatch(setIsRescheduleModalOpen(false));
                    dispatch(setShowMessage(true, 'Event rescheduled successfully.', 'success'));
                }}
                onRescheduleRecurrenceSuccess={(rescheduledRecurrence: RescheduledRecurrenceDto) => {
                    dispatch(setIsRescheduleModalOpen(false));
                    dispatch(setShowMessage(true, 'Recurrence instance event rescheduled successfully.', 'success'));
                }}
            />
            <Grid item lg={6} md={6} sm={12} xs={12}>
                <Grid container spacing={2}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <MemberInfoPanel />
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <SchedulingButtonsPane mode={mode} setOpenEditor={setOpenEditor} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
                <Grid container spacing={2}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <SchedulingEngagementInput />
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        {showAlert && (
                            <Alert severity="warning" sx={{ mb: 2 }}>
                                Please click call and then an action button and leave a note to enable Save and Finish.
                            </Alert>
                        )}
                        <ConfirmBtn
                            disabled={!(selectedCallItem.Note?.length > 0) || !(selectedCallItem.CallStatus?.length > 0)}
                            onClick={doSaveAndFinish}
                            fullWidth
                        >
                            Save and Finish
                        </ConfirmBtn>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <SchedulingEngagementDisplay />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default SchedulingDashboard;
