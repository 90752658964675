import { DataGrid, gridClasses, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';

import { RootState } from '../../../../../../reducers';
import { useDispatch, useSelector } from 'react-redux';
import { clearSelections, getFuhFumAlerts, getTopThreeMatches, setRowSelectionModel, setUnmatchedId } from '../../../../../../store/hie.slice';
import moment from 'moment';
import { isDefaultDate } from '../../../../../../utils/common';
import { useEffect } from 'react';

const FuhFumOpenAlerts = () => {
    const dispatch = useDispatch();

    const { fuhfumAlerts, showArchivedAlerts, unmatchedId } = useSelector((state: RootState) => state.hie);

    const columns: GridColDef[] = [
        {
            field: 'LastName',
            headerName: 'Last Name',
            minWidth: 160,
        },
        {
            field: 'FirstName',
            headerName: 'First Name',
            minWidth: 160,
        },
        {
            field: 'DOB',
            headerName: 'DOB',
            minWidth: 160,
            flex: 1,
            valueGetter: (params: GridValueGetterParams) =>
                `${!moment(params.row.DOB).isValid || !params.row.DOB || isDefaultDate(params.row.DOB) ? 'N/A' : moment.utc(params.row.DOB).format('L')}`,
        },
        {
            field: 'MemberAHCCCS',
            headerName: 'AHCCCS ID',
            flex: 1,
        },
        {
            field: 'SendingFacility',
            headerName: 'Sending Facility',
            minWidth: 270,
            flex: 1.5,
        },
        {
            field: 'VisitType',
            headerName: 'Visit Type',
            minWidth: 200,
            flex: 1,
        },
        {
            field: 'AdmitDate',
            headerName: 'Admission Date',
            minWidth: 160,
            valueGetter: (params: GridValueGetterParams) =>
                `${
                    !moment(params.row.AdmitDate).isValid || !params.row.AdmitDate || isDefaultDate(params.row.AdmitDate)
                        ? 'N/A'
                        : moment.utc(params.row.AdmitDate).format('L')
                }`,
        },
        {
            field: 'DischargeDate',
            headerName: 'Discharge Date',
            minWidth: 160,
            valueGetter: (params: GridValueGetterParams) =>
                `${
                    !moment(params.row.DischargeDate).isValid || !params.row.DischargeDate || isDefaultDate(params.row.DischargeDate)
                        ? 'N/A'
                        : moment.utc(params.row.DischargeDate).format('L')
                }`,
        },
    ];

    useEffect(() => {
        dispatch(getFuhFumAlerts());
    }, [dispatch]);

    return (
        <>
            <DataGrid
                onRowClick={(params: any) => {
                    const { DOB, Id, Patient } = params.row;
                    const names = Patient.split(' ');
                    dispatch(clearSelections());
                    dispatch(setUnmatchedId(Id));
                    dispatch(getTopThreeMatches(names[0], names[names.length - 1], DOB));
                }}
                autoHeight
                rows={showArchivedAlerts ? fuhfumAlerts : fuhfumAlerts.filter((alert) => alert.Archived !== true)}
                columns={columns}
                checkboxSelection={unmatchedId.length > 0 ? true : false}
                onSelectionModelChange={(ids) => {
                    dispatch(setRowSelectionModel(ids));
                }}
                pageSize={10}
                rowsPerPageOptions={[5]}
                getRowId={(row) => row.Id}
                getRowHeight={() => 'auto'}
                getRowClassName={(params) => (params.row.Archived ? 'archived' : '')}
                sx={{
                    [`& .${gridClasses.cell}`]: {
                        py: 1,
                    },
                    [`& .archived`]: {
                        background: 'rgba(200,200,200,0.4)',
                        fontStyle: 'italic',
                    },
                }}
            />
        </>
    );
};

export default FuhFumOpenAlerts;
